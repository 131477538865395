import { timers } from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["status"]
  static values = { "freq": Number, "state": String }

  connect() {
    this.element[this.identifier] = this;
    if (this.hasFreqValue && this.stateValue == "on") {
      $(this.statusTarget).text("Pause live")
      this.setupTimer()
    }
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.refreshTimer) {
      console.log("stopping timer")
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  }

  setupTimer() {
    this.stopTimer()
    this.refreshTimer = setInterval(() => {
      this.refresh()
    }, this.freqValue)
  }

  refresh() {
    let controller = this.getControllerByIdentifier('transporters')
    if (controller) {
      controller.operations_map_view()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  stop() {
    this.stateValue = "off";
    $(this.statusTarget).removeClass("go-live-btn").text("Real Time: Off");
    this.stopTimer();
  }

  start() {
    this.stateValue = "on";
    $(this.statusTarget).addClass("go-live-btn").text("Real Time: On")
    this.setupTimer()
  }

  isRunning() { return this.stateValue == "on" }

  toggle() {
    if (this.stateValue == "on") {
      this.stop()
    } else {
      this.start()
    }
  }
}