import Common from "./common";
import "daterangepicker";
import autosize from "autosize";
import moment from "moment";
import "../../assets/stylesheets/mapboxCar.scss";
import { loadDefaultFiltersOnLoad } from "./operationMapfilter";
import { createTaskGeoJsonObj } from "./base";
import RenderDriverOnMap from "./renderDriverOnMap";
import mapboxgl from "mapbox-gl";

$(document).on("change", "[data-upload='document']", function () {
  Common.readURL(this);
});

document.addEventListener("turbolinks:load", function () {
  App.initMaterialInput();
  Common.handleCountrySelection();
  Common.disableEnterPress();
  Common.loadNiceSelect();
  Common.initDatePicker();
  $("#accordion").on("hide.bs.collapse", function () {
    $(".indicator").html('<span class="fa fa-chevron-down"></span>');
  });
  $("#accordion").on("show.bs.collapse", function () {
    $(".indicator").html('<span class="fa fa-chevron-up"></span>');
  });

  $(".btn-group").on("click", function () {
    var events = $._data(document, "events") || {};
    events = events.click || [];
    for (var i = 0; i < events.length; i++) {
      if (events[i].selector) {
        if ($(event.target).is(events[i].selector)) {
          events[i].handler.call(event.target, event);
        }
        $(event.target)
          .parents(events[i].selector)
          .each(function () {
            events[i].handler.call(this, event);
          });
      }
    }
    event.stopPropagation();
  });

  $(".collapse-right-btn").click(function () {
    $(".operations-right").toggleClass("hide-right-panel");
    $(".operations-right").toggleClass("center-expand-right");

    $(".chevron-right").toggleClass("rotate");
  });

  $(".collapse-left-btn").click(function () {
    let slecetedTask = $(".operations-left").data("selectedtask");
    $(".operations-left").toggleClass("hide-left-panel");

    $(".operations-left").toggleClass("center-expand-left");
    $(".btn-filters").toggleClass("slide__in__filter");
    $(".chevron-left").toggleClass("rotate");
    if (slecetedTask) {
      $(".operations-left").removeData("selectedtask");
    }
  });
  // $("#tab1, #tab2, #tab3").click(function () {
  //   $(".operations-left").addClass("active");
  //   $(".overview-overlay").addClass("active");
  // });
  $(".overview-overlay").click(function () {
    $(".operations-left,.operations-right").removeClass("active");
    $(".overview-overlay").removeClass("active");
  });
  $(".close-btn-left").click(function () {
    $(".overview-overlay").removeClass("active");

    $(".operations-left").removeClass("active");
  });
  console.log($(".transporter"));
  // $(".transporter").click(function () {
  //   console.log("check task");

  //   $(".operations-right").addClass("active");
  //   $(".overview-overlay").addClass("active");
  // });

  $(".close-btn-right").click(function () {
    $(".operations-right").removeClass("active");
    $(".overview-overlay").removeClass("active");
  });

  $(".btn-filter-toggle").click(function () {
    $(".chevron").toggleClass("rotate");
  });

  $(".show__panel").on("click", function () {
    $(this).find(".move").toggleClass("down");
  });

  $(".transporter-details").on("click", "#close-details", () => {
    $(".transporter-details").hide();
    $(".transporters").show();
  });
  $("#task-detailed-info").on("click", "#close-details", () => {
    $("#task-detailed-info").hide();
    $("#tasks-info").show();
  });

  $("#task").on("click", function () {
    $(".task").hide();
    $(".task-details").show();
  });

  $("#close-task-details").on("click", function () {
    $(".task-details").hide();
    $(".task").show();
  });

  autosize($("textarea"));

  $(".abn").mask("00 00 0000");

  $(document).ready(function () {
    $(".expiry-date").mask("00/00", {
      translation: { "00/00": { pattern: /[0-9]/, recursive: true } },
      maxlength: false,
    });
  });

  //toggle-menu bar
  $(document).ready(function () {
    $("#menu-toggle-admin, #close-menu").on("click", function () {
      $("body").toggleClass("sidebar-active");
    });
    $("#menu-toggle-sender, #close-menu-sender").on("click", function () {
      $("body").toggleClass("sidebar-active");
    });
  });

  $(document).on("change keyup paste", "#user-email", function () {
    $.ajax({
      url: "/check_user",
      type: "GET",
      data: {
        email: $(this).val(),
      },
      dataType: "json",
      success: function (response) {
        if (response["status"]) {
          $("#error-msg").text(
            "An account with the email already exists. Click Forgot Password to reset it or contact Transpora for assistance."
          );
        } else {
          $("#error-msg").text("");
        }
      },
    });
  });
});

$(".item").attr("disabled", true);

window.addDateRangePicker = function (element) {
  var start = moment().subtract(6, "days");
  var end = moment();
  $(element).each(function () {
    var startDate = $(this).data("start");
    var endDate = $(this).data("end");
    if (startDate !== undefined) {
      startDate = start;
    } else {
      startDate = moment(startDate);
    }

    if (endDate !== undefined) {
      endDate = end;
    } else {
      endDate = moment(endDate);
    }

    $(this).daterangepicker(
      {
        startDate: startDate,
        locale: {
          format: "D MMM YYYY",
        },
        endDate: endDate,
        ranges: {
          All: ["5-01-2015", moment().add(365, "days")],
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      }
      // cb
    );
    // function cb(start, end) {
    //   debugger;
    //   $(this)
    //     .find("span")
    //     .html(start.format("DD M, YYYY") + " - " + end.format("MMMM D, YYYY"));
    // }
    // cb(start, end);
  });
};

document.addEventListener("turbolinks:load", function () {
  // console.log(window.location.search, "This is search from");
  if ($("#operation_list").length > 0 && window.location.search) {
    const param = window.location.search.substring(1);
    let str = decodeURIComponent(param.replace(/&/g, ",").replace(/=/g, ":"))
      .replace(/[[\]]/g, "")
      .split(",");
    console.log(str);
    const obj = str.reduce(
      (acc, cr) => {
        let [key, value] = cr.split(":");
        console.log(cr, key, typeof value);
        console.log(acc);
        if (key === "query" || key === "start_date" || key === "end_date") {
          acc[key] = value;
        } else {
          acc[key] = [...acc[key], value];
        }
        return acc;
      },
      {
        query: "",
        start_date: "",
        end_date: "",
        city_ids: [],
        pickup_status: [],
        delivery_status: [],
        modes: [],
      }
    );
    $('[data-toggle="daterangepicker"]').data({
      start: obj["start_date"],
      end: obj["end_date"],
    });
    console.log(obj, "you are the best");
    console.log("params there");
    $('[data-toggle="daterangepicker"]').each(function () {
      var startDate = $(this).data("start");
      var endDate = $(this).data("end");
      $(this).daterangepicker({
        startDate: startDate,
        locale: {
          format: "D MMM YYYY",
        },
        endDate: endDate,
        ranges: {
          All: ["5-01-2015", moment().add(365, "days")],
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      });
    });
  } else {
    window.addDateRangePicker('[data-toggle="daterangepicker"]');
  }
});

$(async function () {
  if ($("#map-view").length > 0) {
    mapboxgl.accessToken =
      "pk.eyJ1IjoidHJhbnNwb3JhIiwiYSI6ImNqeTYyenY5dzBjdnEzY3IyNDlkNmg2dHQifQ.W_d3WysAFREko_F9p1XtFA";
    var map = await new mapboxgl.Map({
      container: "map-view", // container id
      style: "mapbox://styles/transpora/ckovhi5og87tz17p5zr8p1rm9", //hosted style id
      center: [144.946457, -37.840935], // starting position
      zoom: 12, // starting zoom
      dragRotate: false,
      // renderWorldCopies: false,
      // minZoom: 3
      // maxZoom: 15
    });
    // make it accessible to controllers
    window.currentMap = map;
    loadDefaultFiltersOnLoad(map);

    // loadImage(map);
    // Initializing the map on the liveTracking

    const renderDriverWithTracking = new RenderDriverOnMap(map);

    // All the filters of the operations map view
    // console.log(transporterCoordinates);

    // FUNCTION for the showDEtails
    // render filter on the map on load
    function renderFilterLayer() {
      // console.log('session data', sessionStorage.driverMode, sessionStorage.driverStatus);
      if (map.getLayer("freeDriver") && map.getLayer("busyDriver")) {
        // console.log('source loaded', map.isSourceLoaded('mainSource'));
        // console.log(map.getLayer('freeDriverPoint'));
        const storedDriverStatus = JSON.parse(sessionStorage.driverStatus);
        const storedDriverMode = JSON.parse(sessionStorage.driverMode);
        // console.log(storedDriverStatus, 'sesson');
        const filteredCheckedDriverStatus = storedDriverStatus.filter(
          ({ isChecked }) => isChecked === true
        );
        const filteredCheckedDriverModes = storedDriverMode.filter(
          ({ isChecked }) => isChecked === true
        );

        for (let driver = 0; driver < storedDriverStatus.length; driver++) {
          if (filteredCheckedDriverModes.length > 0) {
            map.setLayoutProperty(
              `${storedDriverStatus[driver].status.toLowerCase()}Driver`,
              "visibility",
              storedDriverStatus[driver].isChecked ? "visible" : "none"
            );
          } else {
            map.setLayoutProperty(
              `${storedDriverStatus[driver].status.toLowerCase()}Driver`,
              "visibility",
              "none"
            );
          }
        }

        let filterArray = ["any"];
        if (filteredCheckedDriverModes.length > 0) {
          for (
            let driverStatus = 0;
            driverStatus < filteredCheckedDriverStatus.length;
            driverStatus++
          ) {
            let olderLayerFilter = map.getFilter(
              `${filteredCheckedDriverStatus[
                driverStatus
              ].status.toLowerCase()}Driver`
            );
            // console.log(olderLayerFilter);
            for (
              let driverMode = 0;
              driverMode < filteredCheckedDriverModes.length;
              driverMode++
            ) {
              filterArray.push([
                "==",
                ["get", "mode"],
                `${filteredCheckedDriverModes[driverMode].mode}`,
              ]);
            }
            olderLayerFilter.push(filterArray);
            map.setFilter(
              `${filteredCheckedDriverStatus[
                driverStatus
              ].status.toLowerCase()}Driver`,
              olderLayerFilter
            );
            filterArray = ["any"];
          }
        }

        map.off("sourcedata", renderFilterLayer);
      }
    }

    function renderTaskFilterOnMap() {
      if (map.getLayer("pickupTask") && map.getLayer("deliveryTask")) {
        const storedTaskFilter = JSON.parse(sessionStorage.taskFilter);
        console.log(storedTaskFilter, "task stored");
        const allCheckedTask = storedTaskFilter.filter(
          ({ isChecked }) => isChecked === true
        );
        console.log(allCheckedTask, "all checked task");
        if (allCheckedTask.length) {
          let filtersTaskArray = ["any"];
          const appliedFilterOnPickupLayer = map.getFilter("pickupTask");
          const appliedFilterOnDeliveryLayer = map.getFilter("deliveryTask");
          allCheckedTask.forEach(({ taskStatus }) => {
            filtersTaskArray.push(["==", ["get", "status"], taskStatus]);
          });
          console.log(filtersTaskArray, "filter task array");
          appliedFilterOnDeliveryLayer.push(filtersTaskArray);
          appliedFilterOnPickupLayer.push(filtersTaskArray);
          console.log(
            appliedFilterOnDeliveryLayer,
            appliedFilterOnPickupLayer,
            "final filters"
          );
          console.log("taskSource", map.getSource("taskSource"));
          map.setFilter("pickupTask", appliedFilterOnPickupLayer);
          map.setFilter("deliveryTask", appliedFilterOnDeliveryLayer);
        } else {
          map.setLayoutProperty(`pickupTask`, "visibility", "none");
          map.setLayoutProperty(`deliveryTask`, "visibility", "none");
        }
        map.off("sourcedata", renderTaskFilterOnMap);
      }
    }

    async function showTaskDetails(taskId) {
      // console.log($(e.target).data("id"));

      let selectedTask = $(".operations-left").data("selectedtask");
      if (selectedTask !== taskId) {
        await $.ajax({
          url: "/bookings/task_details",
          type: "GET",
          dataType: "script",
          data: { id: taskId },
        });
      }

      if (!selectedTask) {
        console.log(selectedTask);
        selectedTask = taskId;
        $(".operations-left").data("selectedtask", selectedTask);
      }
      console.log("selectedTask", selectedTask);
      if ($(".operations-left").hasClass("hide-left-panel")) {
        $(".operations-left").removeClass("hide-left-panel");
        $(".operations-left").addClass("center-expand-left");
        $(".btn-filters").addClass("slide__in__filter");
        $(".chevron-left").addClass("rotate");
      } else {
        if (selectedTask === taskId) {
          selectedTask = 0;
          $(".operations-left").addClass("hide-left-panel");
          $(".chevron-left").removeClass("rotate");
          $(".operations-left").removeClass("center-expand-left");

          $(".btn-filters").removeClass("slide__in__filter");
        }
      }
      if (selectedTask !== 0) {
        selectedTask = taskId;
        $(".operations-left").data("selectedtask", selectedTask);
      }

      // if ($(".operations-left").hasClass('center-expand-left')) {

      //   $(".operations-left").removeClass("center-expand-left");
      // }
      // if ($(".chevron-left").hasClass('rotate')) {

      // }
      // $(".operations-left").toggleClass("hide-left-panel");
      // $(".operations-left").toggleClass("center-expand-left");
      // $(".chevron-left").toggleClass("rotate");
    }

    function clickListnerForTask() {
      map.on("click", "pickupTask", function (e) {
        const { id } = e.features[0];
        console.log(id, e.features);
        showTaskDetails(id);
      });
      map.on("click", "deliveryTask", function (e) {
        const { id } = e.features[0];
        console.log(id, e.features);
        showTaskDetails(id);
      });
    }

    let oldDate = "";
    function insertMarker() {
      const { deliverytasks, pickuptasks, date, wholetasks } =
        $(".map-view-data").data();
      // console.log(wholeTasks, 'whole tasks');
      // console.log($(".map-view-data").data());
      // for pickup task
      // console.log(oldDate, date);
      // oldDate = date;
      // if (map.getSource('taskSource')) {
      //   map.getSource('taskSource').setData({
      //     type: "FeatureCollection",
      //     features: [],
      //   });

      // }

      if (wholetasks.length > 0) {
        let mapPickupTask = [];
        mapPickupTask = wholetasks.map((item) => {
          const [id, lat, long, status, taskType] = item;
          // renderDriverWithTracking.liveTasksOnMap({ id, geometry: [long, lat], status, taskType: 'pickup' });

          // renderDriverWithTracking.liveTaskFilter2({ id, geometry: [long, lat], status, taskType: 'pickup' });
          return createTaskGeoJsonObj({
            id,
            status,
            geometry: [long, lat],
            taskType,
          });
        });
        console.log(mapPickupTask);
        if (mapPickupTask.length) {
          renderDriverWithTracking.liveTaskFilter2(mapPickupTask);
        }
        // const mapDeliveryTask = deliverytasks.map((item)=>{
        //   const [id,lat,long,status] = item;
        //   return createTaskGeoJsonObj({
        //     id, status, geometry:[long,lat], taskType:''
        //   })
        // })
      } else {
        renderDriverWithTracking.clearTaskSource();
        map.on("sourcedata", renderTaskFilterOnMap);
      }
      // for delivery task
      // if (deliverytasks.length > 0) {
      //   deliverytasks.forEach((item) => {
      //     const [id, lat, long, status] = item;
      //     // renderDriverWithTracking.liveTasksOnMap({ id, geometry: [long, lat], status, taskType: 'delivery' });
      //     console.log(item, 'hii deliver');
      //     renderDriverWithTracking.liveTaskFilter2({ id, geometry: [long, lat], status, taskType: 'delivery' });
      //   });
      // } else {
      //   // renderDriverWithTracking.clearTaskSource();
      //   map.on('sourcedata', renderTaskFilterOnMap);

      // }
    }

    setTimeout(() => {
      insertMarker();
    }, 2000);
    $("#map-data").on("DOMNodeInserted", ".map-view-data", function (e) {
      e.stopPropagation();
      console.log("task <<><><> is loaded");
      insertMarker();
      // methodCount++;
      // if (methodCount === 2) {
      //   methodCount = 0;
      //   insertMarker();
      // }
    });

    // SHOw driver details
    // const showDriverDetail = throttel(showPopUp, 3000);

    // function throttel(func, limit) {
    //   let flag = true;
    //   return function (e) {
    //     if (flag) {
    //       console.log("Throttled ......");
    //       func(e);
    //     }
    //     flag = false;
    //     setTimeout(() => {
    //       flag = true;
    //     }, limit);
    //   };
    // }
    // function to show the driver details
    async function showDriverData(id, status) {
      let { date } = $(".map-view-data").data();
      // let modeIds = $(
      //   '.mode-of-transport input[type="checkbox"]:checked'
      // ).val();
      // let cityIds = $('.all-city input[type="checkbox"]:checked').val();
      // console.log(modeIds, cityIds);

      await $.ajax({
        url: "/bookings/driver_details",
        type: "GET",
        dataType: "script",
        data: {
          id,
          // ...(modeIds && { modes: modeIds }),
          // ...(cityIds && { city_ids: cityIds }),
          date,
          status,
        },
      });
      $(".operations-right").toggleClass("hide-right-panel");
      $(".operations-right").toggleClass("center-expand-right");

      $(".chevron-right").toggleClass("rotate");
      // $(".operations-right").show();
      // $(".center").addClass("center-expand-right");
      // $(".chevron-right").addClass("rotate");
    }
    async function showPopUp() {
      map.on("click", "busyDriver", function (e) {
        const {
          properties: { id, status },
        } = e.features[0];
        console.log(id, Boolean(status));
        showDriverData(id, Boolean(status));
      });

      map.on("click", "freeDriver", function (e) {
        console.log(e.features);
        const {
          properties: { id, status },
        } = e.features[0];
        console.log(id, Boolean(status));
        showDriverData(id, Boolean(status));
      });
      map.on("click", "offlineDriver", function (e) {
        console.log(e.features);
        const {
          properties: { id, status },
        } = e.features[0];
        console.log(id, Boolean(status));
        showDriverData(id, Boolean(status));
      });

      // const { date, id, status } = $(e.target).data();
      // console.log(date, id, status);
      // let modeIds = $('.mode-of-transport input[type="checkbox"]:checked').val();
      // let cityIds = $('.all-city input[type="checkbox"]:checked').val();

      // console.log(modeIds, cityIds);

      // await $.ajax({
      //   url: "/bookings/driver_details",
      //   type: "GET",
      //   dataType: "script",
      //   data: {
      //     id,
      //     ...(modeIds && { modes: modeIds }),
      //     ...(cityIds && { city_ids: cityIds }),
      //     date,
      //     status,
      //   },
      // });
      // $(".operations-right").show();
      // $(".center").addClass("center-expand-right");
      // $(".chevron-right").addClass("rotate");
    }
    showPopUp();
    clickListnerForTask();
    map.on("sourcedata", renderFilterLayer);
    map.on("sourcedata", renderTaskFilterOnMap);

    setInterval(getTransporterCoordinates, 3000);
    async function getTransporterCoordinates() {
      let transportersData = await fetch(
        "https://7t4d76y43j.execute-api.ap-southeast-2.amazonaws.com/dev/"
      );
      let transporterJsonData = await transportersData.json();
      // console.log(">>>>>>>>>>", transporterJsonData);
      if (
        !jQuery.isEmptyObject(transporterJsonData) &&
        transporterJsonData &&
        transporterJsonData.features &&
        transporterJsonData.features.length > 0
      ) {
        // driversDetails(transporterJsonData);
        for (
          let driver = 0;
          driver < transporterJsonData.features.length;
          driver++
        ) {
          // console.log(resp.features[driver]);
          const {
            geometry,
            properties: { id, status, task_id, mode = "car" },
          } = transporterJsonData.features[driver];
          if ((geometry?.coordinates ?? false) && id && status) {
            if (status === "true" && task_id[0] !== 0) {
              // busy driver
              // console.log('Busy one', transporterJsonData.features[driver]);
              // console.log(geometry);
              // console.log(status, task_id);==]]
              // busyDriverTracking({ id, geometry, status, task_id, liveData: resp, driver });
              renderDriverWithTracking.liveDriverOnMap({
                id,
                status,
                task_id,
                geometry,
                mode,
              });
            } else if (status === "true" && task_id[0] === 0) {
              // free driver
              // console.log("Free driver", transporterJsonData.features[driver]);
              // console.log(geometry);
              // busyDriverTracking({ id, geometry, status, task_id, liveData: resp, driver });
              renderDriverWithTracking.liveDriverOnMap({
                id,
                status,
                task_id,
                geometry,
                mode,
              });
            } else {
              // driver is offline
              // console.log("offline driver", transporterJsonData.features[driver]);
              renderDriverWithTracking.offlineDriverOnMap({
                id,
                status,
                task_id,
                geometry,
                mode,
              });
              // offfline({ id, geometry })
              // offfline({ id, geometry, status, task_id, liveData: resp, driver });
            }
          }
        }
      }
    }

    // code for the changing map center
    $('#cities-list li input[type="checkbox"]').on("change", function (e) {
      const { latitude, longitude } = $(this).data();
      $('#cities-list li input[type="checkbox"]').prop("checked", false);
      $(this).prop("checked", true);
      $(".cities-list-dropdown.show ul.all-city.show").removeClass("show");
      $(".cities-list-dropdown.show").removeClass("show");

      map.flyTo({ center: [longitude, latitude] });
    });

    // over view map view mode code inside of it
  }
});

$(function () {
  let isFocusedToggle = false;
  let isBluredToggle = true;
  $("#booking_pickup_delivery_date").on("focus blur", function () {
    $(".dropdown-icon").toggleClass("rotate-dropdown-icon");
  });

  $("body").on("mousedown", function (e) {
    // e.stopPropagation();
    // console.log(isFocusedToggle,isBluredToggle);
    if (!$(e.target).is("button#datePicker--btn,button#datePicker--btn svg")) {
      // console.log(isFocusedToggle,isBluredToggle);

      if (isFocusedToggle && !isBluredToggle) {
        isFocusedToggle = false;
        isBluredToggle = true;
      }
    }
  });

  $("#datePicker--btn").on("click", function () {
    $(this).prev().prop("disabled", false);
    if (!isFocusedToggle && isBluredToggle) {
      isFocusedToggle = true;
      isBluredToggle = false;
      $(this).prev().focus();
    } else {
      isFocusedToggle = false;
      isBluredToggle = true;
      $(this).prev().blur();
    }
    $(this).prev().prop("disabled", true);
  });
});

$(function () {
  // Js for the coverage area table
  if ($("#coverage_area_tbody").length > 0) {
    // console.log('.raka', $('#coverage_area_tbody').length, $('#coverage_area_tbody').height());
    // var div = document.getElementById('coverage_area_tbody');

    const hasVerticalScrollbar = $("#coverage_area_tbody").height() > 600;
    // console.log(hasVerticalScrollbar, div.scrollHeight, div.offsetHeight);
    if (hasVerticalScrollbar) {
      $(".table thead th.check-header").addClass("check-head");
    }
  }
});
