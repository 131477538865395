import { data } from "jquery";
import { Controller } from "stimulus";
import common from "../javascripts/common";
const search = { query: "", city_ids: [], statuses: [], modes: [] };
const performance_search = { query: "", start_date: "", end_date: "", city_ids: [], modes: []}
export default class extends Controller {
  static targets = [
    "query",
    "taskquery",
    "listquery",
    "cities",
    "statuses",
    "transportmodes",
    "submitBtn",
    "editBtn",
    "form",
    "status",
    "reason",
    "selectedDate",
    "freeDriver",
    "busyDriver",
    "offlineDriver",
    "freeDriver",
    "busyDriver",
    "offlineDriver",
    "driverId",
    "driverStatus",
    "page",
    "redirected",
    "performancequery"
  ];

  static values = {
    taskActive: String,
    driverActive: String,
  };

  connect() {
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    this.element[this.identifier] = this;
    if (
      this.hasRedirectedTarget &&
      $(this.redirectedTargets).data("redirect") &&
      window.location.search
    ) {
      console.log(window.location.search);
      $(this.redirectedTargets).data("redirect", false);
      this.setSearchFilter();
    }
    if (this.hasTaskqueryTarget) this.setupTaskSearch();
    if (this.hasQueryTarget) this.setupDriverSearch();
    //if (this.hasListqueryTarget) this.setupDriverListSearch();
    console.log(this.hasPerformancequeryTarget)
    if(this.hasPerformancequeryTarget) this.setupPerformaceListSearch();
    if (this.hasSelectedDateTarget)
      common.selectDatePicker(this.selectedDateTarget, {
        changeYear: false,
        changeMonth: false,
      });
  }

  getTaskActiveTab() {
    var ctrl = $("#task-count-status").get(0).transporters;
    return ctrl.taskActiveValue;
  }

  setTaskActiveTab(value) {
    var ctrl = $("#task-count-status").get(0).transporters;
    ctrl.taskActiveValue = value;
  }

  setDriverActiveTab(value) {
    var ctrl = $("#driver-status-count").get(0).transporters;
    ctrl.taskActiveValue = value;
  }

  getDriverActiveTab() {
    var ctrl = $("#driver-status-count").get(0);
    if (ctrl === undefined) return null;
    return ctrl.transporters.taskActiveValue;
  }

  setupDriverSearch() {
    var box = $(this.queryTarget),
      bar = $("#searchbar").get(0).transporters,
      changeTimer = false,
      self = this;
    box.on("propertychange keyup input paste", function () {
      if (changeTimer !== false) clearTimeout(changeTimer);
      let value = $(this).val();
      if (value.length == 0) {
        bar.operations_map_view().done(function () {});
        return;
      }

      changeTimer = setTimeout(function () {
        self.search_approved_transporters();
        changeTimer = false;
      }, 300);
    });
  }

  setupDriverListSearch() {
    var box = $(this.listqueryTarget),
      changeTimer = false,
      self = this;
    box.on("propertychange keyup input paste", function () {
      if (changeTimer !== false) clearTimeout(changeTimer);

      changeTimer = setTimeout(function () {
        self.search_transporters();
        changeTimer = false;
      }, 300);
    });
  }

  setupPerformaceListSearch(){
    var box = $(this.performancequeryTarget),
      changeTimer = false,
      self = this;
    box.on("propertychange keyup input paste", function () {
      if (changeTimer !== false) clearTimeout(changeTimer);

      changeTimer = setTimeout(function () {
        self.search_transporter_performance();
        changeTimer = false;
      }, 300);
    });
  }

  setupTaskSearch() {
    var box = $(this.taskqueryTarget),
      bar = $("#searchbar").get(0).transporters,
      changeTimer = false,
      self = this;
    box.on("propertychange keyup input paste", function () {
      if (changeTimer !== false) clearTimeout(changeTimer);

      let value = $(this).val();
      if (value.length == 0) {
        bar.operations_map_view().done(function () {});
        return;
      }

      changeTimer = setTimeout(function () {
        self.mapview_tasks_search();
        changeTimer = false;
      }, 300);
    });
  }

  mapview_tasks_search() {
    var bar = $("#searchbar").get(0).transporters,
      booking_search = {};

    if (this.taskqueryTarget) {
      var term = $(this.taskqueryTarget).val();
      console.log("taskquery term", term);
      booking_search["query"] = term;
      if (term.length < 2) {
        return;
      }
    }

    booking_search = this.currentFilters(booking_search, bar);
    booking_search["start_date"] = booking_search["date"];
    booking_search["end_date"] = booking_search["date"];

    return $.ajax({
      url: "/bookings/map_view_tasks_search",
      type: "GET",
      dataType: "script",
      data: booking_search,
    });
  }

  driver_list(ev) {
    var bar = $("#searchbar").get(0).transporters;
    var scope = this.currentFilters({}, bar);
    scope["status"] = $(ev.currentTarget).data("dstatus");
    scope["query"] = $(ev.currentTarget).data("dquery");

    this.setDriverActiveTab(scope["status"]);

    $.ajax({
      url: "/bookings/drivers_list_status",
      type: "GET",
      dataType: "script",
      data: scope,
    });
  }

  driver_info_tasks(ev) {
    ev.stopPropagation();
    this.driver_info().done(function () {
      $(".transporter-details").find(".nav-item a:last").trigger("click");
    });
  }

  driver_info() {
    var driverId = this.driverIdTarget.dataset.driverid;
    var status = this.driverIdTarget.dataset.status;
    var date = this.driverIdTarget.dataset.date;
    var modes = this.driverIdTarget.dataset.modes;
    var city_ids = this.driverIdTarget.dataset.cities;

    return $.ajax({
      url: "/bookings/driver_details",
      type: "GET",
      dataType: "script",
      data: {
        id: driverId,
        status: status,
        date: date,
        modes: modes,
        city_ids: city_ids,
      },
    });
    // return $.ajax({
    //   url: "/overview",
    //   type: "GET",
    //   dataType: "script",
    //   data: {
    //     id: driverId,
    //     status: status,
    //     date: date,
    //     modes: modes,
    //     city_ids: city_ids,
    //   },
    // });
  }

  update_driver_status() {
    var id = this.driverStatusTarget.dataset.id;
    var date = this.driverStatusTarget.dataset.date;
    var modes = this.driverStatusTarget.dataset.modes;
    var city_ids = this.driverStatusTarget.dataset.cities;
    $.ajax({
      url: `/admin/transporters/${id}/update_status`,
      type: "PATCH",
      dataType: "script",
      data: { date: date, modes: modes, city_ids: city_ids },
    });
  }

  search_transporters() {
    if (this.citiesTargets) {
      search["city_ids"] = [];
      this.citiesTargets.map((t) => {
        if (t.checked) {
          search["city_ids"].push(t.value);
        }
      });
    }

    if (this.statusesTargets) {
      search["statuses"] = [];
      this.statusesTargets.map((t) => {
        if (t.checked) {
          search["statuses"].push(t.value);
        }
      });
    }

    if (this.transportmodesTargets) {
      search["modes"] = [];
      this.transportmodesTargets.map((t) => {
        if (t.checked) {
          search["modes"].push(t.value);
        }
      });
    }

    if (this.hasListqueryTarget) {
      this.listqueryTargets.map((t) => {
        search["query"] = t.value;
      });
    }

    $.ajax({
      url: "/admin/transporters",
      type: "GET",
      dataType: "script",
      data: search,
    });
  }

  search_transporter_performance(){
    if(this.citiesTargets){
      performance_search["city_ids"] = []
      this.citiesTargets.map((t) => {
        if(t.checked){
          performance_search["city_ids"].push(t.value)
        }
      });
    }

    if(this.transportmodesTargets){
      performance_search["modes"] = []
      this.transportmodesTargets.map((t) => {
        if(t.checked){
          performance_search["modes"].push(t.value)
        }
      })
    }

    if(this.hasPerformancequeryTarget){
      performance_search["query"] = ""
      this.performancequeryTargets.map((t)=>{
        performance_search["query"] = t.value;
      });
    }
    //console.log(performance_search)
    $.ajax({
      url: "transporters_performance",
      type: "GET",
      dataType: "script",
      data: performance_search,
    })
  }

  search_booking_transporters(ev) {
    var scope = {};
    scope.query = $(this.queryTarget).val();
    if (scope.query.length == 0) {
      scope.query = $(ev.currentTarget).val();
    }

    return $.ajax({
      url: "/admin/transporters/get_approved_transporters",
      type: "GET",
      dataType: "script",
      data: scope,
    });
  }

  search_approved_transporters() {
    var bar = $("#searchbar").get(0).transporters;
    var scope = this.currentFilters({}, bar);

    if (this.statusesTargets) {
      scope["statuses"] = [];
      this.statusesTargets.map((t) => {
        if (t.checked) {
          scope["statuses"].push(t.value);
        }
      });
    }

    this.queryTargets.map((t) => {
      scope["query"] = t.value;
    });

    if (scope["query"].length < 2) {
      return;
    }

    return $.ajax({
      url: "/admin/transporters/get_approved_transporters",
      type: "GET",
      dataType: "script",
      data: scope,
    });
  }

  onCityChanged(ev) {
    const currentCity = $(ev.currentTarget).val();
    $('#cities-list li input[type="checkbox"]').prop("checked", false);
    $(ev.currentTarget).prop("checked", true);
    $(".cities-list-dropdown.show ul.all-city.show").removeClass("show");
    $(".cities-list-dropdown.show").removeClass("show");

    if (window.currentMap) {
      const map = window.currentMap;
      if (currentCity === "1") {
        map.flyTo({ center: [144.946457, -37.840935] });
      } else {
        map.flyTo({ center: [151.2099, -33.865143] });
      }
    }
    this.operations_map_view();
  }

  currentFilters(qq, instance) {
    if (instance === "undefined") {
      instance = this;
    }

    if (instance.citiesTargets) {
      qq["city_ids"] = [];
      instance.citiesTargets.map((t) => {
        if (t.checked) {
          qq["city_ids"].push(t.value);
        }
      });
    }

    if (instance.transportmodesTargets) {
      qq["modes"] = [];
      instance.transportmodesTargets.map((t) => {
        if (t.checked) {
          qq["modes"].push(t.value);
        }
      });
    }

    if (instance.hasSelectedDateTarget) {
      var sDate = new Date(instance.selectedDateTarget.value);
      var selectedDate =
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate();
      qq["date"] = selectedDate;
    }

    var status = this.getTaskActiveTab();
    if (status && status !== undefined) {
      qq["task_status"] = status;
    }

    var status = this.getDriverActiveTab();
    if (status && status !== undefined) {
      qq["driver_status"] = status;
    }

    return qq;
  }

  operations_map_view() {
    var tasksearchctrl = $("#task-search").get(0).transporters,
      driversearchctrl = $("#driver-search").get(0).transporters;
    var task_term = $(tasksearchctrl.taskqueryTarget).val();
    search["query"] = task_term;

    var driver_term = $(driversearchctrl.queryTarget).val();
    search["driver_query"] = driver_term;

    if (this.citiesTargets) {
      search.city_ids = [];
      this.citiesTargets.map((t) => {
        if (t.checked) {
          search.city_ids.push(t.value);
        }
      });
    }

    if (this.transportmodesTargets) {
      search["modes"] = [];
      this.transportmodesTargets.map((t) => {
        if (t.checked) {
          search["modes"].push(t.dataset.mode);
        }
      });
    }

    if (this.haSelectedDateTarget) {
      var sDate = new Date(this.selectedDateTarget.value);
      var selectedDate =
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate();
      search["date"] = selectedDate;
    }

    var status = this.getTaskActiveTab();
    if (status && status !== undefined) {
      search["task_status"] = status;
    }

    var status = this.getDriverActiveTab();
    if (status && status !== undefined) {
      search["driver_status"] = status;
    }

    console.log(this.hasPageTarget, "are you ready to do this");
    // debugger;
    if (this.hasPageTarget) {
      return $.ajax({
        url: "/overview",
        type: "GET",
        dataType: "script",
        data: search,
      });
    } else {
      return $.ajax({
        url: "/bookings/operations_map_view",
        type: "GET",
        dataType: "script",
        data: search,
      });
    }
  }

  show_hide_update() {
    this.submitBtnTarget.classList.remove("d-none");
    this.formTarget.classList.remove("form-inactive");
    this.editBtnTarget.classList.add("d-none");
  }

  showDriverDetails(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    var driver = $(ev.currentTarget).data("driver");
    if (driver) {
      console.log(driver);
      $("#transporter-" + driver).trigger("click");
      $(".operations-right")
        .removeClass("hide-right-panel")
        .addClass("center-expand-right");
      $(".operations-right").addClass("center-expand-right active");
      $(".chevron-right").addClass("rotate");
    }
  }
  setSearchFilter() {
    if (window.location.search) {
      const param = window.location.search.substring(1);
      let str = decodeURIComponent(param.replace(/&/g, ",").replace(/=/g, ":"))
        .replace(/[[\]]/g, "")
        .split(",");
      console.log(str);
      const obj = str.reduce(
        (acc, cr) => {
          let [key, value] = cr.split(":");
          console.log(cr, key, typeof value);
          console.log(acc);
          if (key === "query") {
            acc[key] = value;
          } else {
            acc[key] = [...acc[key], value];
          }
          return acc;
        },
        { query: "", city_ids: [], modes: [], statuses: [] }
      );
      console.log(obj, "you are the best");
      for (let key in obj) {
        search[key] = obj[key];
      }
      console.log(search);
      this.transportmodesTargets.map((t) => {
        if (search["modes"].includes(t.value)) {
          t.checked = true;
        }
      });
      this.statusesTargets.map((s) => {
        if (search["statuses"].includes(s.value)) {
          s.checked = true;
        }
      });
      this.citiesTargets.map((city) => {
        if (search["city_ids"].includes(city.value)) {
          city.checked = true;
        }
      });
    }
  }
  getSearchFilter() {
    return search;
  }
}

// click listener for overview cards for transporter section
// filter code for date

$(function () {
  console.log(window.location.pathname);
  if (window.location.pathname !== "/overview") {
    var date = $("#date-of-booking");

    date.on("change", function (e) {
      let ctrl = $("#searchbar").get(0).transporters;
      let search = ctrl.getSearchFilter();
      var sDate = new Date(e.currentTarget.value);
      var selectedDate =
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate();
      search["date"] = selectedDate;
      // debugger;
      if (e.currentTarget.dataset?.page === "overview") {
        $.ajax({
          url: "/overview",
          type: "GET",
          dataType: "script",
          data: search,
        });
      } else {
        $.ajax({
          url: "/bookings/operations_map_view",
          type: "GET",
          dataType: "script",
          data: search,
        });
      }
    });
  }
});

$(function () {
  if (window.location.pathname !== "/overview") {
    $("#tasks-status").on("click", "a", function (e) {
      let query = {};
      let ctrl = $("#searchbar").get(0).transporters;
      let search = ctrl.getSearchFilter();

      $("#tasks-status .nav-item a").removeClass("active");
      $(e.currentTarget).addClass("active");

      var status = $(e.currentTarget).data("taskstatus");
      if (status) {
        query.status = status;
      }

      var term = $("#task-search input").val();
      if (term !== undefined) {
        query.query = term;
      }

      var date = $(e.currentTarget).data("taskdate");
      if (date) {
        query.date = date;
      }
      var city_ids = search.city_ids;
      if (city_ids) {
        query.city_ids = city_ids;
      }

      if (search.modes && search.modes.length > 0) {
        query.modes = search.modes;
      }

      // var ctrl = $("#searchbar").get(0).transporters;
      ctrl.setTaskActiveTab(status);
      console.log(query);

      $.ajax({
        url: "/bookings/task_list_status_wise",
        type: "GET",
        dataType: "script",
        data: query,
      });
    });
  }
});

$(function () {
  if (window.location.pathname !== "/overview") {
    let isFocusedToggle = false;
    let isBluredToggle = true;
    $("#booking_pickup_delivery_date").on("focus blur", function () {
      $(".dropdown-icon").toggleClass("rotate-dropdown-icon");
    });

    $("body").on("mousedown", function (e) {
      // e.stopPropagation();
      // console.log(isFocusedToggle,isBluredToggle);
      if (
        !$(e.target).is("button#datePicker--btn,button#datePicker--btn svg")
      ) {
        // console.log(isFocusedToggle,isBluredToggle);

        if (isFocusedToggle && !isBluredToggle) {
          isFocusedToggle = false;
          isBluredToggle = true;
        }
      }
    });

    $("#datePicker--btn").on("click", function () {
      $(this).prev().prop("disabled", false);
      if (!isFocusedToggle && isBluredToggle) {
        isFocusedToggle = true;
        isBluredToggle = false;
        $(this).prev().focus();
      } else {
        isFocusedToggle = false;
        isBluredToggle = true;
        $(this).prev().blur();
      }
      $(this).prev().prop("disabled", true);
    });
  }
});

var updateDateRange = function (value) {
  var parts = value.split("-");
  if (parts.length > 1) {
    var start_date = parts[0];
    var end_date = parts[1];
    performance_search["start_date"] = start_date;
    performance_search["end_date"] = end_date;
    console.log("filter daterange", start_date, end_date);
  }
};

$(function(){
  var date = $("#transporterreport");
  date.on("change",function(e){
    updateDateRange(e.target.value);
    $.ajax({
      url: "transporters_performance",
      type: "GET",
      dataType: "script",
      data: performance_search,
    })
  })
})

$(document).on('turbolinks:load',function(){
  $(function(){
    $("#transporter-performance").val(performance_search["query"]);
    if(performance_search["start_date"] && performance_search["end_date"]){
      $("#transporterreport").val(performance_search["start_date"]+"-"+performance_search["end_date"])
    }
    $("#cities li").each(function(){
      var city = $(this).find('input').val()
      if(performance_search["city_ids"].includes(city)){
        $(this).find('input').prop("checked",true)
      }
    })
    $("#modes li").each(function(){
      var mode = $(this).find('input').val()
      if(performance_search["modes"].includes(mode)){
        $(this).find('input').prop("checked",true)
      }
    })
  })
  $(function(){
    $("#tran-per-xl").on("click",function(){
      var query = performance_search["query"]
      var start_date = performance_search["start_date"]
      var end_date = performance_search["end_date"]
      var city_ids = performance_search["city_ids"]
      var modes = performance_search["modes"]
      $("#tran-per-xl").attr("href","/admin/transporters/transporters_performance.xlsx?query="+query+"&start_date="+start_date+"&end_date="+end_date+"&city_ids="+city_ids+"&modes="+modes+"");
    })
  })
})



$(document).on('turbolinks:load',function(){
  $(function(){
    $("#transporter-search").val(search["query"]);
    $("#city-names li").each(function(){
      var city = $(this).find('input').val()
      console.log(search["city_ids"])
      if(search["city_ids"].includes(city)){
        $(this).find('input').prop("checked",true)
      }
    })
    $("#statuses li").each(function(){
      var status = $(this).find('input').val()
      if(search["statuses"].includes(status)){
        $(this).find('input').prop("checked",true)
      }
    })
    $("#transport-modes li").each(function(){
      var mode = $(this).find('input').val()
      if(search["modes"].includes(mode)){
        $(this).find('input').prop("checked",true)
      }
    })
  })
  $(function(){
    $("#tran-xl").on("click",function(){
      var query = search["query"]
      var city_ids = search["city_ids"]
      var statuses = search["statuses"]
      var modes = search["modes"]
      $("#tran-xl").attr("href","/admin/transporters.xlsx?query="+query+"&city_ids="+city_ids+"&statuses="+statuses+"&modes="+modes+"");
    })
  })
})


// overview screen handler
// function toChangePage(url, dates) {
//   // console.log(search);
//   let ctrl = $("#searchbar").get(0).transporters;
//   let search = ctrl.getSearchFilter();
//   console.log(search);
//   let query = { query: "", city_ids: [], modes: [], statuses: [0] };
//   // url: "/admin/transporters",
//   if (search && search?.city_ids?.length > 0) {
//     query.city_ids = search.city_ids;
//   }
//   if (search && search?.modes?.length > 0) {
//     query.modes = search.modes;
//   }
//   if (dates) {
//     console.log(ctrl.selectedDateTarget.value);
//     if (ctrl?.selectedDateTarget?.value) {
//       query["start_date"] = ctrl.selectedDateTarget.value;
//       query["end_date"] = ctrl.selectedDateTarget.value;
//       query["statuses"] = [];
//     }
//   }

//   console.log(query);
//   console.log($.param(query));
//   window.location.replace(`${url}?query=&${$.param(query)}`);
// }

// $(function () {
//   if (window.location.pathname !== "/overview") {
//     $("#transporter-data,#overview-tasks").on("click", ".card ", function (e) {
//       console.log("ram is going to market");
//       let ctrl = $(".overview-container #searchbar").get(0).transporters;
//       let search = ctrl.getSearchFilter();
//       let query = {};
//       if ($(e.currentTarget).hasClass("transporter")) {
//         $(
//           `.overview_bodywrap #driver-status-count ul .nav-item .nav-link`
//         ).removeClass("active");
//         $(
//           `.overview_bodywrap #driver-status-count ul .nav-item .nav-link[data-dstatus=${$(
//             e.currentTarget
//           ).data("dstatus")}]`
//         ).addClass("active");
//         ctrl.driver_list(e);
//         $(".operations-right").addClass("active");
//         $(".overview-overlay").addClass("active");
//       } else if ($(e.currentTarget).hasClass("awaiting-review")) {
//         toChangePage("/admin/transporters");
//       } else {
//         const taskStatus = $(e.currentTarget).data("taskstatus");
//         console.log(taskStatus);
//         let element = $(
//           `.overview_bodywrap #tasks-status .nav-item a.nav-link[data-taskstatus='${taskStatus}']`
//         );
//         $(".overview_bodywrap #tasks-status .nav-item a").removeClass("active");
//         element.addClass("active");

//         let status = element.data("taskstatus");
//         if (status) {
//           query.status = status;
//         }

//         let term = $(".overview_bodywrap #task-search input").val();
//         if (term !== undefined) {
//           query.query = term;
//         }

//         let date = element.data("taskdate");
//         if (date) {
//           query.date = date;
//         }
//         let city_ids = search.city_ids;
//         if (city_ids) {
//           query.city_ids = city_ids;
//         }

//         if (search.modes && search.modes.length > 0) {
//           query.modes = search.modes;
//         }

//         let ctrl = $(".overview-container #searchbar").get(0).transporters;
//         ctrl.setTaskActiveTab(status);
//         console.log(query);

//         $.ajax({
//           url: "/bookings/task_list_status_wise",
//           type: "GET",
//           dataType: "script",
//           data: query,
//         });
//         console.log(element);
//         $(".operations-left").addClass("active");
//         $(".overview-overlay").addClass("active");
//       }
//     });

//     // click listener for finanacial section

//     $(".overview_bodywrap .financials").on("click", ".card", function (e) {
//       toChangePage("/bookings/operations_list_view", true);
//     });
//   }
// });
