$(document).on("change", "#transport-mode", function() {
  $(".profile-fields").removeClass('d-none');
  var mode = $(this).val();
  var vehicle_details = $("#vehicle-details");
  var type_mode = ["walking", "bicycle"];
  if (type_mode.includes(mode)) {
    $("#drivers-licence-label").text("Drivers Licence or Student ID or National ID");
    vehicle_details.addClass("d-none");
    vehicle_details.find("input, select").addClass("ignored");
    $("#insurance-doc").addClass("d-none");
  } else {
    $("#drivers-licence-label").text("Australian Drivers Licence")
    vehicle_details.removeClass("d-none");
    vehicle_details.find("input, select").removeClass("ignored");
    $("#insurance-doc").removeClass("d-none");
  }
});

$(document).on("change", "#transporter-status", function() {
  var status = $(this).val();
  if (status == "needs_resubmission") {
    $("#resubmission-reason").removeClass("d-none");
    $("#reason").removeClass("ignored");
    $("#city-select").addClass("d-none")
    $("#city-id").addClass("ignored")
  } else if(status == "approved") {
    $("#resubmission-reason").addClass("d-none");
    $("#reason").addClass("ignored")
    $("#city-select").removeClass("d-none")
    $("#city-id").removeClass("ignored")
  } else {
    $("#city-select").addClass("d-none")
    $("#city-id").addClass("ignored")
    $("#resubmission-reason").addClass("d-none");
    $("#reason").addClass("ignored")
  }
});

$(document).on("click", '[data-button="upload"]', function(e) {
  var file_field = $(this).data("input");
  if ($(this).data("avatar")) {
    var input_length = $(this)
      .next(".document-field")
      .children(".file-input").length;
    if (input_length > 0) {
      e.preventDefault();
    } else {
      $(this)
        .next(".document-field")
        .append(file_field);
    }
  } else {
    $(this)
      .next(".document-field")
      .append(file_field);
  }
});

$(document).on("click", ".remove-doc", function() {
  $(this)
    .parent()
    .remove();
});