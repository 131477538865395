import IntlTelInput from "intl-tel-input";
import "javascripts/jquery.nice-select.min";
var countryData = window.intlTelInputGlobals.getCountryData();
// $("#myModal").on("shown.bs.modal", function () {
//   $("#myInput").trigger("focus");
// });
class Common {
  loadNiceSelect(type) {
    $("select.select-field").niceSelect(type);
  }
  handleNumberMask() {
    $('[data-toggle="mask"]').each(function (item) {
      $(this).mask($(this).data("mask"));
    });
  }
  zoom() {
    var newTab = window.open();
    var data = document.getElementById("modal-img").getAttribute("image_tag");
    setTimeout(function () {
      newTab.document.body.innerHTML = "<img src='" + data + "'>";
    }, 500);
    return false;
  }
  readURL(input) {
    var input_element = $(input);
    var total_files = input.files.length;
    if (input.files) {
      for (var i = 0; i < total_files; i++) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var result = e.target.result;
          input_element.next("img").attr("src", result);
          input_element.parent().addClass("file-img-preview");
        };
        reader.readAsDataURL(input.files[i]);
      }
    }
  }
  disableEnterPress() {
    var valid_text_field = $('[data-action="disable-enter"]');
    var form = valid_text_field.parents("form");
    form.on("keypress", function (e) {
      if (e.keyCode == 13) {
        return false;
      }
    });
  }
  handleCountrySelection() {
    $('[data-select="country-code"]').each(function () {
      var selectedCountryCode, selectedCountry, selectedIso2, input;
      input = $(this)[0];
      selectedCountryCode = $(this).data("selected-country");
      if (selectedCountryCode) {
        selectedCountry = countryData.filter(function (country) {
          return country.dialCode == selectedCountryCode;
        });
      }
      if (selectedCountry) {
        selectedIso2 = selectedCountry[0]["iso2"].toUpperCase();
      } else {
        selectedIso2 = "AU";
      }
      var iti = IntlTelInput(input, {
        initialCountry: selectedIso2,
        preferredCountries: "",
        formatOnDisplay: false,
        autoPlaceholder: false,
      });
      setTimeout(function () {
        $(".iti .iti__country-list").width($(".iti input").outerWidth());
      }, 200);
      $(input).parent().next().val(iti.selectedCountryData.dialCode);
      input.addEventListener("countrychange", function () {
        $(input).parent().next().val(iti.selectedCountryData.dialCode);
      });
    });
  }
  selectDatePicker(selector, options){
    if(options === undefined) options = {};
    options = $.extend({}, {
      changeYear: true,
      changeMonth: true,
      dateFormat: "dd M yy",
      yearRange: "-5:+5"
    }, options);

    $(selector).each(function(){
      var element = $(this);
      var initialDate = element.data("date");
      if(initialDate === undefined || initialDate.length == 0){
        initialDate = new Date()
      } else {
        initialDate = new Date(initialDate);
      }
      // console.log("initialDate", initialDate);
      element.datepicker(options);
      element.datepicker('setDate', initialDate);
    });
  }
  selectDate() {
    this.selectDatePicker("#date-of-birth,#map-view-datepicker");
  }
  initDatePicker() {
    $("[data-toggle='datepicker'], [data-toggle='sender_pickup_delivery_date']").each(
      function () {
        let minDate = 0;
        let dataMinDate = $(this).data("mindate");
        if(dataMinDate !== undefined){
          minDate = dataMinDate;
        }
        $(this).datepicker({
          minDate: minDate,
          dateFormat: "dd M yy",
          onSelect: function () {
            this.dispatchEvent(new Event("change"));
          },
        });
      }
    );   
    $("[data-toggle='datepicker'], [data-toggle='pickup_delivery_date']").each(
      function () {
        $(this).datepicker({
          dateFormat: "dd M yy",
          yearRange: "2020:+0",
          onSelect: function () {
            this.dispatchEvent(new Event("change"));
          },
        });
      }
    );
    $("[data-toggle='edit_pickup_delivery_date']").each(function () {
      $(this).datepicker({
        dateFormat: "dd M yy",
        yearRange: "2020:+0",
        onSelect: function () {
          this.dispatchEvent(new Event("change"));
        },
      });
    });
    $("[data-toggle='datepicker']")
      .datepicker()
      .datepicker("setDate", new Date());
    $("[data-toggle='map-view-datepicker']")
      .datepicker({ dateFormat: "dd M yy" })
      .datepicker("setDate", new Date());
  }
}

$(document).ready(function () {
  $("#shout-out1").on("click", function () {
    $("#shout-out2, #shout-out3").addClass("blur");
    $("#shout-out1").removeClass("blur");
  });
});
$(document).ready(function () {
  $("#shout-out2").on("click", function () {
    $("#shout-out1, #shout-out3").addClass("blur");
    $("#shout-out2").removeClass("blur");
  });
});
$(document).ready(function () {
  $("#shout-out3").on("click", function () {
    $("#shout-out1, #shout-out2").addClass("blur");
    $("#shout-out3").removeClass("blur");
  });
});

$(document).ready(function () {
  $("#stars li")
    .on("mouseover", function () {
      $(this)
        .parent()
        .children("li.star")
        .each(function (e) {
          if (e < onStar) {
            $(this).addClass("hover");
          } else {
            $(this).removeClass("hover");
          }
        });
    })
    .on("mouseout", function () {
      $(this)
        .parent()
        .children("li.star")
        .each(function (e) {
          $(this).removeClass("hover");
        });
    });

  $("#stars li").on("click", function () {
    var onStar = parseInt($(this).data("value"), 10);
    var stars = $(this).parent().children("li.star");

    for (var i = 0; i < stars.length; i++) {
      $(stars[i]).removeClass("selected");
    }

    for (i = 0; i < onStar; i++) {
      $(stars[i]).addClass("selected");
    }
  });
});

export default new Common();
