import { CheckForTheDriver, createGeoJsonObj, onRenderDriverOnMap, renderTasksOnMap } from './base';
import driveVehcile from './trackingLogic';

// Function for render driver on map
export default class RenderDriverOnMap {
  map;
  constructor(map) {
    this.map = map;
  }

  liveDriverOnMap({ id, geometry, status, task_id, mode }) {
    if (this.map.getSource('mainSource')) {
      const { data, driverIndex } = CheckForTheDriver({ map: this.map, dataSource: "mainSource", id });
      if (driverIndex !== -1) {
        const { id: previousDriverId, status: previousDriverStatus, task_id: previousDriverTaskId } = data.features[driverIndex].properties;
        // console.log(data);
        // console.log(`previousDriverId:${previousDriverId}

        // ,currentId:${id},preivouStatus:${previousDriverStatus}
        // ,currentStatus:${status},previousTaskId ${previousDriverTaskId},currentTaskId:${task_id}`);
        if (previousDriverTaskId !== task_id[0] || previousDriverStatus !== status || previousDriverId !== id) {
          data.features[driverIndex] = createGeoJsonObj({ id, task_id, status, geometry, mode })
          onRenderDriverOnMap(this.map, data);
        }

        const { geometry: previousDriverGeometry } = data.features[driverIndex];
        // console.log('driver current status', turf.booleanEqual(previousDriverGeometry, geometry));

        if (!turf.booleanEqual(previousDriverGeometry, geometry)) {
          // driveVehcile(id, geometry)();
          driveVehcile(this.map, id, geometry, mode)();
        }
      } else {
        data.features.push(createGeoJsonObj({ id, status, task_id, geometry, mode }));
        onRenderDriverOnMap(this.map, data);
      }
    } else {
      onRenderDriverOnMap(this.map, {
        type: "FeatureCollection",
        features: [
          createGeoJsonObj({
            id,
            task_id,
            status,
            geometry,
            mode
          })

        ],
      })
    }
  }
  offlineDriverOnMap({ id, geometry, status, task_id, mode }) {
    if (this.map.getSource('mainSource')) {
      const { data, driverIndex } = CheckForTheDriver({ map: this.map, dataSource: "mainSource", id });
      if (driverIndex !== -1) {
        const { id: previousDriverId, status: previousDriverStatus, task_id: previousDriverTaskId } = data.features[driverIndex].properties;
        if (previousDriverTaskId !== task_id[0] || previousDriverStatus !== status || previousDriverId !== id) {
          data.features[driverIndex] = createGeoJsonObj({
            id, status, geometry, task_id, mode
          })
          onRenderDriverOnMap(this.map, data);
        }
      } else {
        data.features.push(createGeoJsonObj({
          id,
          status,
          task_id,
          geometry,
          mode
        }));
        onRenderDriverOnMap(this.map, data);
      }
    } else {
      onRenderDriverOnMap(this.map, {
        type: "FeatureCollection",
        features: [
          createGeoJsonObj({
            id, status, task_id, geometry,
            mode
          })

        ],
      })
    }
  }


  // for live task on map

  // liveTasksOnMap({ id, geometry, status, taskType }) {

  //   // console.log(id, geometry, status);

  //   if (this.map.getSource('taskSource')) {
  //     const { data, driverIndex: taskIndex } = CheckForTheDriver({ map: this.map, dataSource: 'taskSource', id });
  //     // console.log('task source present');
  //     // console.log(data, taskIndex);
  //     if (taskIndex !== -1) {
  //       const { id: prevId, properties: { status: prevStatus }, geometry: prevGeometry } = data.features[taskIndex];
  //       if (id !== prevId || status !== prevStatus || !turf.booleanEqual({

  //         type: "Point",
  //         coordinates: geometry,


  //       }, prevGeometry)) {
  //         console.log('update previ');
  //         data.features[taskIndex] = createTaskGeoJsonObj({ id, status, geometry, taskType });
  //         renderTasksOnMap(this.map, data);
  //       }
  //     } else {
  //       console.log('new task appear');
  //       data.features.push(createTaskGeoJsonObj({ id, status, geometry, taskType }));
  //       renderTasksOnMap(this.map, data);
  //     }
  //     // console.log(this.map.getSource('taskSource'));
  //     // console.log(this.map.getLayer('pickupTask'));
  //   } else {
  //     renderTasksOnMap(this.map, {
  //       type: "FeatureCollection",
  //       features: [
  //         createTaskGeoJsonObj({
  //           id, status, geometry, taskType
  //         })

  //       ],
  //     })
  //   }
  //   // const {} = CheckForTheDriver(id,dataSource:'taskSource');


  // }
  // alternative task func
  liveTaskFilter2(data) {
    renderTasksOnMap(this.map, {
      type: "FeatureCollection",
      features: data,
    });
  }

  // clear task source data
  clearTaskSource() {
    if (this.map.getSource('taskSource')) {
      this.map.removeLayer('deliveryTask');
      this.map.removeLayer('pickupTask');
      this.map.removeSource('taskSource');
    }
  }
}