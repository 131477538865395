
import { defaultDriverStatus, defaultModeOfDriver, defaultTaskFilter } from './base';


function filterFunction({ map, driverModeFilter, driverStatusFilter, taskMapFilter }) {
  for (let driverMode = 0; driverMode < driverModeFilter.length; driverMode++) {
    let { mode, isChecked } = driverModeFilter[driverMode];
    const element = $(`.mode-of-transport li [type="checkbox"]:eq(${driverMode})`);
    // console.log(element);
    element.val(mode);
    element.attr('checked', isChecked);
    element.change((e) => {
      // console.log(map);
      modeFilter({ e, map, driverModeFilter, driverStatusFilter });
    });
  }
  // end of first for loop
  for (let driverStatus = 0; driverStatus < driverStatusFilter.length; driverStatus++) {

    const { status, isChecked } = driverStatusFilter[driverStatus];
    const element = $(`.transporters-filter:eq(${driverStatus})`);
    element.val(status);
    element.attr('checked', isChecked);
    element.change((e) => {
      // console.log("driver status mode", status);
      transportersStatusFilter({ e, map, driverModeFilter, driverStatusFilter, driverIndex: driverStatus });
    });
  }
  // for loop for the task filters stored in session storage
  for (let task = 0; task < taskMapFilter.length; task++) {
    const { taskStatus, isChecked } = taskMapFilter[task];
    const element = $(`.task-map-filters:eq(${task})`);
    // console.log(element);
    element.val(taskStatus);
    element.attr('checked', isChecked);
    element.change((e) => {
      console.log(map);
      console.log('task filter');
      taskFilter({ e, map, taskMapFilter, taskIndex: task });
    });
  }
}

export function loadDefaultFiltersOnLoad(map) {
  if (!sessionStorage.driverStatus || !sessionStorage.driverMode || !sessionStorage.taskFilter) {
    sessionStorage.driverMode = JSON.stringify(defaultModeOfDriver);
    sessionStorage.driverStatus = JSON.stringify(defaultDriverStatus);
    sessionStorage.taskFilter = JSON.stringify(defaultTaskFilter);
    filterFunction({ map, driverModeFilter: defaultModeOfDriver, driverStatusFilter: defaultDriverStatus, taskMapFilter: defaultTaskFilter });
  } else {
    const storedDriverStatus = JSON.parse(sessionStorage.driverStatus);
    const storedModeOfDriver = JSON.parse(sessionStorage.driverMode);
    const storedTaskFilter = JSON.parse(sessionStorage.taskFilter);
    filterFunction({ map, driverModeFilter: storedModeOfDriver, driverStatusFilter: storedDriverStatus, taskMapFilter: storedTaskFilter });
  }
}

function modeFilter({ e, map, driverModeFilter, driverStatusFilter }) {
  const currentIndex = driverModeFilter.findIndex(({ mode }) => mode === e.target.value);
  console.log(map, 'map<<><><>');
  driverModeFilter[currentIndex].isChecked = e.target.checked;

  sessionStorage.driverMode = JSON.stringify(driverModeFilter);
  console.log(driverModeFilter);
  const allCheckedStatus = driverStatusFilter.filter(({ isChecked }) => isChecked === true);
  if (e.target.checked) {
    if (allCheckedStatus.length > 0) {
      allCheckedStatus.forEach((item) => {
        let { status, isChecked } = item;
        console.log(map.getFilter('freeDriver'));
        const appliedFilterOnLayer = map.getFilter(`${status.toLowerCase()}Driver`);
        if (appliedFilterOnLayer[appliedFilterOnLayer.length - 1].includes('any')) {
          console.log(appliedFilterOnLayer[appliedFilterOnLayer.length - 1]);
          const moreFilter = appliedFilterOnLayer[appliedFilterOnLayer.length - 1];
          console.log('more Filter', 'this is more filter', moreFilter);
          moreFilter.push(["==", ['get', 'mode'], `${e.target.value}`]);
          appliedFilterOnLayer[appliedFilterOnLayer.length - 1] = moreFilter
          map.setFilter(`${status.toLowerCase()}Driver`, appliedFilterOnLayer);
        } else {
          let filterArray = ["any"];

          filterArray.push(["==", ['get', 'mode'], `${e.target.value}`]);
          appliedFilterOnLayer.push(filterArray);
          map.setFilter(`${status.toLowerCase()}Driver`, appliedFilterOnLayer);
        }
        map.setLayoutProperty(
          `${status.toLowerCase()}Driver`,
          'visibility',
          isChecked ? 'visible' : 'none'
        );
      })
    }
  } else {

    if (allCheckedStatus.length > 0) {
      const allCheckedMode = driverModeFilter.filter(({ isChecked }) => isChecked === true);
      allCheckedStatus.forEach((item) => {
        let { status, isChecked } = item;
        const appliedFilterOnLayer = map.getFilter(`${status.toLowerCase()}Driver`);

        const appliedFilters = appliedFilterOnLayer[appliedFilterOnLayer.length - 1];
        let updatedArrayAfterRemove = appliedFilters.filter((item) => {
          return !item.includes(e.target.value);
        });
        if (updatedArrayAfterRemove.length > 1) {
          appliedFilterOnLayer[appliedFilterOnLayer.length - 1] = updatedArrayAfterRemove;
        } else {
          appliedFilterOnLayer.pop();
        }
        map.setFilter(`${status.toLowerCase()}Driver`, appliedFilterOnLayer);
        if (allCheckedMode.length > 0) {
          map.setLayoutProperty(
            `${status.toLowerCase()}Driver`,
            'visibility',
            isChecked ? 'visible' : 'none'
          );

        } else {
          map.setLayoutProperty(
            `${status.toLowerCase()}Driver`,
            'visibility',
            'none'
          );

        }
      })
    }
  }
}

// map filters
function transportersStatusFilter({ e, map, driverModeFilter, driverStatusFilter, driverIndex }) {
  const currentIndex = driverStatusFilter.findIndex(({ status }) => status === e.target.value);
  console.log(map, 'map<<><><>');
  driverStatusFilter[currentIndex].isChecked = e.target.checked;

  sessionStorage.driverStatus = JSON.stringify(driverStatusFilter);
  console.log(driverModeFilter, driverStatusFilter);
  const appliedFilterOnLayer = map.getFilter(`${e.target.value.toLowerCase()}Driver`);
  if (e.target.checked) {

    let filterArray = ["any"];

    const allCheckedMode = driverModeFilter.filter(({ isChecked }) => isChecked === true);
    if (allCheckedMode.length > 0) {
      for (let driverMode = 0; driverMode < allCheckedMode.length; driverMode++) {
        filterArray.push(["==", ['get', 'mode'], `${allCheckedMode[driverMode].mode}`]);
      }
      appliedFilterOnLayer.push(filterArray);
      console.log("filter after push", appliedFilterOnLayer);
      map.setFilter(`${e.target.value.toLowerCase()}Driver`, appliedFilterOnLayer);
      map.setLayoutProperty(
        `${driverStatusFilter[driverIndex].status.toLowerCase()}Driver`,
        'visibility',
        'visible'
      );

    } else {
      map.setLayoutProperty(
        `${driverStatusFilter[driverIndex].status.toLowerCase()}Driver`,
        'visibility',
        'none'
      );
    }

  } else {
    if (appliedFilterOnLayer[appliedFilterOnLayer.length - 1].includes('any')) {

      // console.log(`filter for ${e.target.id.toLowerCase()}`);

      // const appliedFilterOnLayer = map.getFilter(`${e.target.id.toLowerCase()}DriverPoint`);
      appliedFilterOnLayer.pop();
      // appliedFilterOnLayer.pop();
      console.log('This is after pop', appliedFilterOnLayer);
      map.setFilter(`${e.target.value.toLowerCase()}Driver`, appliedFilterOnLayer);
    }
    map.setLayoutProperty(
      `${driverStatusFilter[driverIndex].status.toLowerCase()}Driver`,
      'visibility',
      'none'
    );
  }
  console.log(e.target.value);
}

function taskFilter({ e, map, taskMapFilter, taskIndex }) {
  // console.log(taskIndex, e.target);
  // const currentIndex = taskMapFilter.findIndex(({ taskStatus }) => taskStatus === e.target.value);
  // console.log(map, taskMapFilter[currentIndex], 'map<<><><>');
  // console.log(taskMapFilter[taskIndex]);
  // console.log(map.getLayoutProperty('pickupTask', 'visibility'));
  taskMapFilter[taskIndex].isChecked = e.target.checked;
  sessionStorage.taskFilter = JSON.stringify(taskMapFilter);
  if (map.getLayer('pickupTask') || map.getLayer('deliveryTask')) {

    map.setLayoutProperty(
      `pickupTask`,
      'visibility',
      'visible'
    );
    map.setLayoutProperty(
      `deliveryTask`,
      'visibility',
      'visible'
    );

    const appliedFilterOnPickupLayer = map.getFilter('pickupTask');
    const appliedFilterOnDeliveryLayer = map.getFilter('deliveryTask');
    console.log(appliedFilterOnPickupLayer, appliedFilterOnDeliveryLayer);
    if (e.target.checked) {
      if (appliedFilterOnDeliveryLayer[appliedFilterOnDeliveryLayer.length - 1].includes('any') && appliedFilterOnPickupLayer[appliedFilterOnPickupLayer.length - 1].includes('any')) {

        const appliedAnyFilterOnPickup = appliedFilterOnPickupLayer[appliedFilterOnPickupLayer.length - 1];
        const appliedAnyFilterOnDelivery = appliedFilterOnDeliveryLayer[appliedFilterOnDeliveryLayer.length - 1];

        appliedAnyFilterOnPickup.push(['==', ["get", "status"], `${e.target.value}`]);
        appliedAnyFilterOnDelivery.push(["==", ["get", "status"], `${e.target.value}`]);
        appliedFilterOnDeliveryLayer[appliedFilterOnDeliveryLayer.length - 1] = appliedAnyFilterOnDelivery;
        appliedFilterOnPickupLayer[appliedFilterOnPickupLayer.length - 1] = appliedAnyFilterOnPickup;
        map.setFilter('pickupTask', appliedFilterOnPickupLayer);
        map.setFilter('deliveryTask', appliedFilterOnDeliveryLayer);


      } else {
        let filterTaskArray = ["any"];
        filterTaskArray.push(["==", ["get", "status"], `${e.target.value}`]);
        console.log(filterTaskArray);
        appliedFilterOnPickupLayer.push(filterTaskArray);
        appliedFilterOnDeliveryLayer.push(filterTaskArray);
        map.setFilter('pickupTask', appliedFilterOnPickupLayer);
        map.setFilter('deliveryTask', appliedFilterOnDeliveryLayer);

      }
    } else {

      const appliedAnyFilterOnPickup = appliedFilterOnPickupLayer[appliedFilterOnPickupLayer.length - 1];
      const appliedAnyFilterOnDelivery = appliedFilterOnDeliveryLayer[appliedFilterOnDeliveryLayer.length - 1];
      // if(appliedAnyFilterOnDelivery &&appliedAnyFilterOnDeliver.length || ){

      // }
      if (appliedAnyFilterOnDelivery.length > 1 && appliedAnyFilterOnPickup.length > 1) {
        const filteredRemoveArrayPickup = appliedAnyFilterOnPickup.filter((item) => {
          return !item.includes(e.target.value);
        });
        const filteredRemoveArrayDelivery = appliedAnyFilterOnDelivery.filter((item) => {
          return !item.includes(e.target.value);
        });
        appliedFilterOnPickupLayer[appliedFilterOnPickupLayer.length - 1] = filteredRemoveArrayPickup;
        appliedFilterOnDeliveryLayer[appliedFilterOnDeliveryLayer.length - 1] = filteredRemoveArrayDelivery;
        // console.log(appliedAnyFilterOnPickup);
        map.setFilter('pickupTask', appliedFilterOnPickupLayer);
        map.setFilter('deliveryTask', appliedFilterOnDeliveryLayer);


      } else {
        appliedFilterOnDeliveryLayer.pop();
        appliedFilterOnPickupLayer.pop();
        map.setFilter('pickupTask', appliedFilterOnPickupLayer);
        map.setFilter('deliveryTask', appliedFilterOnDeliveryLayer);
      }
    }
  }
}
