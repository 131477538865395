import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["window"];
    update_window_status() {
      var time_window = this.windowTargets[0];
      this.windowTargets.map(t => {
        var status = (t.value === 'false') ? true : false
        console.log("AM here with", t.value, status, t.dataset.id);
        this.updateWindowStatus(t.dataset.id, status).then(response => {
          time_window.value = status;
        });            
      })
    }

    updateWindowStatus(id,status) {
      return $.ajax({
        url: `/admin/time_windows/${id}`,
        type: "PATCH",
        dataType: "script",
        data: { time_window:{status: status} }          
      });
    }    
}
