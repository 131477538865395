$(document).on("click, focus", '[data-toggle="autocomplete"]', function () {
  initAutocomplete($(this));
});
// Google Autocomplete
var placeSearch, autocomplete;

// location autocomplete
function initAutocomplete(autocomplete_input) {
  if (autocomplete_input != "") {
    autocomplete = new google.maps.places.Autocomplete(autocomplete_input[0], {
      types: ["geocode", "establishment"],
      // componentRestrictions: {
      //   country: "IN",
      // },
    });

    google.maps.event.addListener(autocomplete, "place_changed", function () {
      var place = autocomplete.getPlace();
      console.log(place);
      var lat,
        lng,
        full_address,
        postal_code,
        administrative_area_level_1,
        country,
        user_type,
        locality;
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      adm_area = getAdministrativeArea(place).split("-")[0];
      country = getAdministrativeArea(place).split("-")[1];
      locality = fillInAddress(place, "locality");
      postal_code = fillInAddress(place, "postal_code");
      full_address = place.formatted_address;
      user_type = $("#user_type").val();
      autocomplete_input.data("dirty", true);
      switch (autocomplete_input.data("id")) {
        case "pickup_address":
          $("#pickup_latitude").val(lat);
          $("#pickup_longitude").val(lng);
          if (place) {
            $("#pickup_short_address").val(fillInAddress(place, ""));
          }
          if (user_type == "sender") {
            areaDemand(
              "pickup",
              lat,
              lng,
              adm_area,
              postal_code,
              full_address,
              country,
              locality
            );
          }
          break;
        case "delivery_address":
          $("#delivery_latitude").val(lat);
          $("#delivery_longitude").val(lng);
          if (place) {
            $("#delivery_short_address").val(fillInAddress(place, ""));
          }
          if (user_type == "sender") {
            areaDemand(
              "delivery",
              lat,
              lng,
              adm_area,
              postal_code,
              full_address,
              country,
              locality
            );
          }
          break;
        case "additional_address":
          $("#additional_pickup_latitude").val(lat);
          $("#additional_pickup_longitude").val(lng);
          break;
        case "coverage-area":
          searchAddress(postal_code);
        default:
          $("#latitude").val(lat);
          $("#longitude").val(lng);
      }
      if (place) {
        $("#short-address").val(fillInAddress(place, ""));
      }

      // if(autocomplete_input.data("id") === "pickup_address") {
      //   $('#pickup_latitude').val(place.geometry.location.lat());
      //   $('#pickup_longitude').val(place.geometry.location.lng());
      // }else{
      //   $('#delivery_latitude').val(place.geometry.location.lat());
      //   $('#delivery_longitude').val(place.geometry.location.lng());
      // }
      //alert("This function is working!");
      //alert(place.name);
      // alert(place.address_components[0].long_name);
    });
  }
}
function throttel(delay, fn) {
  let lastCall = 0;
  return function (...args) {
    let now = new Date().getTime();

    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}
const func = throttel(1000, (target, revert) => {
  if (!revert) {
    switch (target.data("id")) {
      case "pickup_address":
        $("#pickup_latitude").val("");
        $("#pickup_longitude").val("");

        break;
      case "delivery_address":
        $("#delivery_latitude").val("");
        $("#delivery_longitude").val("");

        break;
    }
  } else {
    switch (target.data("id")) {
      case "pickup_address":
        $("#pickup_latitude").val($("#pickup_latitude").data("lat"));
        $("#pickup_longitude").val($("#pickup_longitude").data("lon"));

        break;
      case "delivery_address":
        $("#delivery_latitude").val($("#delivery_latitude").data("lat"));
        $("#delivery_longitude").val($("#delivery_longitude").data("lon"));

        break;
    }
  }
});
$(document).on("input", '[data-toggle="autocomplete"]', (e) => {
  const target = $(e.target);
  if (window.location.pathname.endsWith("/edit")) {
    console.log($(e.target).data("dirty"));
    if ($(e.target).data("dirty")) {
      $(e.target).data("dirty", false);
    } else {
      if (target.val() !== target.data("address")) {
        if (
          ($("#pickup_latitude").val() && $("#pickup_longitude").val()) ||
          ($("#delivery_latitude").val() && $("#delivery_longitude").val())
        ) {
          func(target, false);
        }
      } else {
        if (
          !($("#pickup_latitude").val() && $("#pickup_longitude").val()) ||
          !($("#delivery_latitude").val() && $("#delivery_longitude").val())
        ) {
          $(e.target).data("dirty", true);
          func(target, true);
        }
      }
    }
  }
});
// $(document).on("input", '[data-toggle="autocomplete"]', (e) => {
//   const target = $(e.target);
//   if (window.location.pathname.endsWith("/edit")) {
//     console.log($(e.target).data("dirty"));
//     if ($(e.target).data("dirty")) {
//       $(e.target).data("dirty", false);
//     } else {
//       if (target.val() !== target.data("address")) {
//         if (
//           ($("#pickup_latitude").val() && $("#pickup_longitude").val()) ||
//           ($("#delivery_latitude").val() && $("#delivery_longitude").val())
//         ) {
//           func(target);
//         }
//       }
//     }
//   }
// });

function fillInAddress(place, type) {
  let locality, state, postalCode;
  // Get each component of the address from the place details
  // and fill the corresponding field on the form.
  // using the reverse loop as the country is present after the state
  for (var i = place.address_components.length - 1; i >= 0; i--) {
    var addressType = place.address_components[i].types[0];
    // if (addressType === "administrative_area_level_1") {
    //   state = place.address_components[i]["short_name"];
    // }
    if (addressType === "locality") {
      state = place.address_components[i]["short_name"];
    }
    if (addressType === "locality") {
      locality = place.address_components[i]["short_name"];
    } else if (addressType === "sublocality_level_1") {
      locality = place.address_components[i]["short_name"];
    }

    if (addressType === "postal_code") {
      postalCode = place.address_components[i]["short_name"];
    }

    // if(){

    // }
    // if (componentForm[addressType]) {
    //   switch (addressType) {
    //     case "country":
    //       var country_long_val = place.address_components[i]["long_name"];
    //       var country_short_val = place.address_components[i]["short_name"];
    //       address_group
    //         .find('[data-group-address="country"]')
    //         .val(country_long_val)
    //         .trigger("change");
    //       address_group
    //         .find('[data-group-address="country_short"]')
    //         .val(country_short_val);
    //       break;
    //     case "administrative_area_level_1":
    //       var state_long_val = place.address_components[i]["long_name"];
    //       var state_short_val = place.address_components[i]["short_name"];
    //       address_group
    //         .find('[data-group-address="administrative_area_level_1"]')
    //         .val(state_long_val);
    //       address_group
    //         .find('[data-group-address="administrative_area_level_1_short"]')
    //         .val(state_short_val);
    //       break;
    //     default:
    //       var val = place.address_components[i][componentForm[addressType]];
    //       address_group
    //         .find("[data-group-address=" + addressType + "]")
    //         .val(val);
    //   }
    // }
  }
  if (type == "locality") {
    return `${(locality && locality) || ""}`;
  }

  if (type == "postal_code") {
    return `${(postalCode && postalCode) || ""}`;
  }

  console.log(`${locality} ${state} ${postalCode} asdfadf`);
  console.log(
    `${(locality && locality) || ""} ${
      (locality === state ? "" : state) || ""
    } ${(postalCode && postalCode) || ""} correct`
  );
  return `${(locality && locality) || ""} ${
    (locality === state ? "" : state) || ""
  } ${(postalCode && postalCode) || ""}`;
}

function getAdministrativeArea(place) {
  for (var i = place.address_components.length - 1; i >= 0; i--) {
    var addressType = place.address_components[i].types[0];
    var administrative_area_level_1, country;
    if (addressType === "administrative_area_level_1") {
      administrative_area_level_1 = place.address_components[i][
        "long_name"
      ].replace(/ +/g, " ");
    }
    if (addressType === "country") {
      country = place.address_components[i]["long_name"].replace(/ +/g, " ");
    }
  }
  return `${
    (administrative_area_level_1 && administrative_area_level_1) || ""
  }-${(country && country) || ""}`;
}

function searchAddress(postalCode) {
  $.ajax({
    url: `/admin/coverage_areas/search_coverage_areas?postal_code=${postalCode}`,
    type: "GET",
  });
}

function areaDemand(
  task_type,
  latitude,
  longitude,
  administrative_area,
  postal_code,
  full_address,
  country,
  locality
) {
  $.ajax({
    url: `/bookings/check_coverage_area`,
    type: "GET",
    data: {
      task_type,
      latitude,
      longitude,
      administrative_area,
      postal_code,
      full_address,
      country,
      locality,
    },
  });
}
