// import { data } from "jquery";
import { Controller } from "stimulus";

import warning from "../images/warning.svg";

var barcodes_global_count = 0;
var total_price = 0;
var service_fee = 0;
const search = { query: "", search: "" };
const booking_search = {
  query: "",
  start_date: "",
  end_date: "",
  city_ids: [],
  pickup_status: [],
  delivery_status: [],
  modes: [],
};

export default class BookingsController extends Controller {
  static values = {
    loadPrices: Boolean,
    searchTask: Boolean,
  };

  static targets = [
    "smallItem",
    "mediumItem",
    "largeItem",
    "smallItemValue",
    "mediumItemValue",
    "largeItemValue",
    "barcodes",
    "pickupFavouriteValue",
    "deliveryFavouriteValue",
    "pickupBusinessName",
    "pickupName",
    "pickupMobileNumber",
    "pickupCountryCode",
    "pickupEmail",
    "pickupUnitLevel",
    "pickupAddress",
    "pickupSpecialInstruction",
    "deliveryBusinessName",
    "deliveryName",
    "deliveryMobileNumber",
    "deliveryCountryCode",
    "deliveryEmail",
    "deliveryUnitLevel",
    "deliveryAddress",
    "deliverySpecialInstruction",
    "deliveryAuthorityToLeave",
    "totalPrice",
    "serviceFee",
    "barcodes",
    "pickupDeliveryDate",
    "pickupSelectedWindow",
    "query",
    "availableDriverInfo",
    "driverId",
    "availableDrivers",
    "selectedDriver",
    "searchDrivers",
    "transportmodes",
    "cities",
    "pickupstatuses",
    "deliverystatuses",
    "selectedDateRange",
    "search",
    "removeDriver",
    "additionaldriversearch",
    "additionalTransporter",
    "showAvailableDrivers",
    "showSelectedDriver",
    "tasksInfo",
    "invoicestatuses",
    "assignmentCheckboxes",
    "addpickupSelectedWindow",
    "currentDriverId",
    "additionalCurrentDriverId",
    "redirected",
  ];

  connect() {
    this.element[this.identifier] = this;
    if (
        this.hasRedirectedTarget &&
        $(this.redirectedTargets).data("redirect") &&
        window.location.search
    ) {
      $(this.redirectedTargets).data("redirect", false);
      this.setAllBookingFilters();
    }
    if (this.loadPricesValue) {
      this.getPriceInfo().then((response) => {
        const prices = response;
        this.small_item_price = parseFloat(prices.small);
        this.medium_item_price = parseFloat(prices.medium);
        this.large_item_price = parseFloat(prices.large);
        this.service_fee_percentage = parseFloat(prices.service_fee_percentage);
      });
    }
  }

  reloadList() {
    this.transportersController.reload();
  }

  get TransportersController() {
    return this.application.getControllerForElementAndIdentifier(
        this.element,
        "list"
    );
  }

  small_add() {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;

    var item_count = Number(small + 1);
    if (small < 10) {
      barcodes_global_count = total_box + 1;
      var barcode_count =
          barcodes_global_count > 0 ? barcodes_global_count : item_count;
      var price = this.small_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (service_fee + current_serviceFee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.smallItemValueTarget.value = item_count;
      this.smallItemTarget.innerHTML = item_count;
      $("#bar-codes").append(
          '<div class="material-input form-group item-barcode"><label for="barcode_' +
          barcode_count +
          '" class="bmd-label-floating">Enter Barcode ' +
          barcode_count +
          ' <span class="mandatory-star">*</span></label><input type="text" data-barcode="barcode" data-target="bookings.barcodes" data-action="keyup->bookings#search_barcode_uniq" name="booking[barcodes][]"  placeholder= " " class= "form-control" id="barcode_' +
          barcode_count +
          '"></div>'
      );
      App.initMaterialInput();
    } else if (small == 10) {
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can only select up
       </br> to 10 quantities per item type</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't add more than 10 small boxes");
    }
  }

  small_reduce(event) {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;
    if (small > 0) {
      var item_count = Number(small - 1);
    }
    if (small != 0 && small <= 10) {
      var price = this.small_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (current_serviceFee - service_fee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.smallItemValueTarget.value = item_count == "0" ? "0" : item_count;
      this.smallItemTarget.innerHTML = item_count;
      $(".item-barcode:last-child").remove();
      barcodes_global_count = total_box - 1;
    } else if (small == 0) {
      event.preventDefault();
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can't reduce more small boxes</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't reduce more small boxes");
    }
  }

  medium_add() {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;
    var item_count = Number(medium + 1);
    if (medium < 10) {
      barcodes_global_count = total_box + 1;
      var barcode_count =
          barcodes_global_count > 0 ? barcodes_global_count : item_count;
      var price = this.medium_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (service_fee + current_serviceFee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.mediumItemValueTarget.value = item_count;
      this.mediumItemTarget.innerHTML = item_count;
      $("#bar-codes").append(
          '<div class="material-input form-group item-barcode"><label for="barcode_' +
          barcode_count +
          '" class="bmd-label-floating">Enter Barcode ' +
          barcode_count +
          ' <span class="mandatory-star">*</span></label><input type="text" data-barcode="barcode" data-target="bookings.barcodes" data-action="keyup->bookings#search_barcode_uniq" name="booking[barcodes][]"  placeholder= " " class= "form-control" id="barcode_' +
          barcode_count +
          '"></div>'
      );
      App.initMaterialInput();
    } else if (medium == 10) {
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can only select up 
      </br> to 10 quantities per item type</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't add more than 10 medium boxes");
    }
  }

  medium_reduce(event) {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;
    if (medium > 0) {
      var item_count = Number(medium - 1);
    }
    if (medium != 0 && medium <= 10) {
      var price = this.medium_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (current_serviceFee - service_fee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.mediumItemValueTarget.value = item_count == "0" ? "0" : item_count;
      this.mediumItemTarget.innerHTML = item_count;
      $(".item-barcode:last-child").remove();
      barcodes_global_count = total_box - 1;
    } else if (medium == 0) {
      event.preventDefault();
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can't reduce more medium boxes</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't reduce more medium boxes");
    }
  }

  large_add() {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;
    var item_count = Number(large + 1);
    if (large < 10) {
      barcodes_global_count = total_box + 1;
      var barcode_count =
          barcodes_global_count > 0 ? barcodes_global_count : item_count;
      var price = this.large_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (service_fee + current_serviceFee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          total_price + current_totalPrice
      ).toFixed(2);
      this.largeItemValueTarget.value = item_count;
      this.largeItemTarget.innerHTML = item_count;
      $("#bar-codes").append(
          '<div class="material-input form-group item-barcode"><label for="barcode_' +
          barcode_count +
          '" class="bmd-label-floating">Enter Barcode ' +
          barcode_count +
          ' <span class="mandatory-star">*</span></label><input type="text" data-barcode="barcode" data-target="bookings.barcodes" data-action="keyup->bookings#search_barcode_uniq" name="booking[barcodes][]"  placeholder= " " class= "form-control" id="barcode_' +
          barcode_count +
          '"></div>'
      );
      App.initMaterialInput();
    } else if (large == 10) {
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can only select up 
      </br> to 10 quantities per item type</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't add more than 10 large boxes");
    }
  }

  large_reduce(event) {
    var small = Number(this.smallItemTarget.innerHTML);
    var medium = Number(this.mediumItemTarget.innerHTML);
    var large = Number(this.largeItemTarget.innerHTML);
    var total_box = small + medium + large;
    if (large > 0) {
      var item_count = Number(large - 1);
    }
    if (large != 0 && large <= 10) {
      var price = this.large_item_price;
      total_price = price;
      service_fee =
          (parseFloat(total_price).toFixed(2) * this.service_fee_percentage) /
          100;
      var current_serviceFee =
          Number(this.serviceFeeTarget.value) > 0
              ? Number(this.serviceFeeTarget.value)
              : 0;
      var current_totalPrice =
          Number(this.totalPriceTarget.value) > 0
              ? Number(this.totalPriceTarget.value)
              : 0;
      this.serviceFeeTarget.value = (current_serviceFee - service_fee).toFixed(
          2
      );
      this.totalPriceTarget.value = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.totalPriceTarget.innerHTML = parseFloat(
          current_totalPrice - total_price
      ).toFixed(2);
      this.largeItemValueTarget.value = item_count == "0" ? "0" : item_count;
      this.largeItemTarget.innerHTML = item_count;
      $(".item-barcode:last-child").remove();
      barcodes_global_count = total_box - 1;
    } else if (large == 0) {
      event.preventDefault();
      let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Sorry you can't reduce more large boxes</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonAriaLabel: "Close",
      });
      // alert("Sorry can't reduce more large boxes");
    }
  }

  assign_pickup_favourite_location() {
    var id = this.pickupFavouriteValueTarget.value;
    var favr_this = this;
    $.ajax({
      url: `/favourite_locations/${id}/get_favourite_location`,
      dataType: "json",
      type: "GET",
      success: function (data) {
        var favourite_location = data["favourite_location"]
        favr_this.pickupBusinessNameTarget.value =
            favourite_location["business_name"];
        favr_this.pickupNameTarget.value = favourite_location["name"];
        favr_this.pickupMobileNumberTarget.value =
            favourite_location["mobile_number"];
        favr_this.pickupCountryCodeTarget.value =
            "+" + favourite_location["mobile_country_code"];
        console.log(">>>>>>>", favr_this.pickupCountryCodeTarget.value);
        if (favourite_location["email"] != undefined) {
          favr_this.pickupEmailTarget.value = favourite_location["email"];
        }
        if (favourite_location["unit_level_number"] != undefined) {
          favr_this.pickupUnitLevelTarget.value =
              favourite_location["unit_level_number"];
        }
        favr_this.pickupAddressTarget.value = favourite_location["address"];
        if (favourite_location["special_instructions"] != undefined) {
          favr_this.pickupSpecialInstructionTarget.value =
              favourite_location["special_instructions"];
        }
        App.initMaterialInput();
        $("#pickup_latitude").val(data["lat"]);
        $("#pickup_longitude").val(data["long"]);
        $("#pickup_short_address").val(data["short_address"]);
      },
    });
  }

  getPriceInfo() {
    return $.ajax({
      url: "/bookings/get_price_info",
      type: "GET",
    });
  }

  assign_delivery_favourite_location() {
    var id = this.deliveryFavouriteValueTarget.value;
    var favr_this = this;
    $.ajax({
      url: `/favourite_locations/${id}/get_favourite_location`,
      type: "GET",
      dataType: "json",
      success: function (data) {
        var favourite_location = data["favourite_location"];
        favr_this.deliveryBusinessNameTarget.value =
            favourite_location["business_name"];
        favr_this.deliveryNameTarget.value = favourite_location["name"];
        favr_this.deliveryMobileNumberTarget.value =
            favourite_location["mobile_number"];
        favr_this.deliveryCountryCodeTarget.value =
            "+" + favourite_location["mobile_country_code"];
        if (favourite_location["email"] != undefined) {
          favr_this.deliveryEmailTarget.value = favourite_location["email"];
        }
        if (favourite_location["unit_level_number"] != undefined) {
          favr_this.deliveryUnitLevelTarget.value =
              favourite_location["unit_level_number"];
        }
        if (favourite_location["address"] != undefined) {
          favr_this.deliveryAddressTarget.value = favourite_location["address"];
        }
        if (favourite_location["special_instructions"] != undefined) {
          favr_this.deliverySpecialInstructionTarget.value =
              favourite_location["special_instructions"];
        }
        favr_this.deliveryAuthorityToLeaveTarget.value =
            favourite_location["authority_to_leave"];

        App.initMaterialInput();
        $("#delivery_latitude").val(data["lat"]);
        $("#delivery_longitude").val(data["long"]);
        $("#delivery_short_address").val(data["short_address"]);
      },
    });
  }

  search_barcode_uniq(event) {
    var barcodes = new Array();
    // var barcode = $("[data-barcode='barcode']").val();
    this.barcodesTargets.map((t) => {
      if (t.value.length > 7) {
        if (barcodes.includes(t.value)) {
          // event.preventDefault();
          alert("Sorry " + t.value + " barcode already exist");
        } else if (!barcodes.includes(t.value)) {
          barcodes.push(t.value);
        }
      }
    });
  }

  datepicker_pickup_window() {
    var sDate = new Date(this.pickupDeliveryDateTarget.value);
    $("[name='booking[pickup_delivery_date]']").val($("[name='booking[pickup_delivery_date]']").val().replace(/^0+/g,""))
    var selectedDate =
        sDate.getFullYear() +
        "-" +
        (sDate.getMonth() + 1) +
        "-" +
        sDate.getDate();
    var todayDate = new Date();
    var currentDate =
        todayDate.getFullYear() +
        "-" +
        (todayDate.getMonth() + 1) +
        "-" +
        todayDate.getDate();
    var hour = todayDate.getHours() - 12;
    this.getTimeWindows(selectedDate == currentDate).then((response) => {
      var pickupWindowArray = response.time_windows;
      var optionsAsString = "";
      for (var i = 0; i < pickupWindowArray.length; i++) {
        optionsAsString +=
            "<option value='" +
            pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "'>" +
            pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "</option>";
      }
      $("#booking_pickup_window  option").remove();
      $("#booking_delivery_window option").remove();
      $("#booking_delivery_window").append("<option value>Select</option>");
      $("#booking_pickup_window").append(
          "<option value>Select</option>" + optionsAsString
      );
      App.Common.loadNiceSelect("update");
    });
  }
  admin_datepicker_pickup_window(){
    $("[name='booking[pickup_delivery_date]']").val($("[name='booking[pickup_delivery_date]']").val().replace(/^0+/g,""))
    var  pickupWindowArray = $('.admin_pickup_window option').toArray().map(o=>o.value);
    var optionsAsString = "";
    for(var i = 1; i < pickupWindowArray.length; i++){
      optionsAsString +=
          "<option value='" +
          pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
          "'>" +
          pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
          "</option>";
    }
    $("#booking_pickup_window  option").remove();
    $("#booking_pickup_window").append(
        "<option value>Select</option>" + optionsAsString
    );
    App.Common.loadNiceSelect("update");
  }
  getTimeWindows(status) {
    return $.ajax({
      url: "/time_windows_info",
      dataType: "json",
      type: "GET",
      data: { status: status },
    });
  }

  pickup_window() {
    var pickup_window = this.pickupSelectedWindowTarget.value.split("-")[0];
    this.getDeliverTimeWindows(pickup_window).then((response) => {
      var deliveryWindowArray = response.time_windows;
      var optionsAsString = "";
      for (var i = 0; i < deliveryWindowArray.length; i++) {
        optionsAsString +=
            "<option value='" +
            deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "'>" +
            deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "</option>";
      }
      // $('#booking_pickup_window').addClass("nice-select nice-selector");
      $("#booking_delivery_window option").remove();
      $("#booking_delivery_window").append(
          "<option value>Select</option>" + optionsAsString
      );
      App.Common.loadNiceSelect("update");
    });
  }

  pickup_window_admin(){
    var pickup_window = this.pickupSelectedWindowTarget.value.split("-")[0];
    this.getDeliverTimeWindows(pickup_window).then((response) => {
      var deliveryWindowArray = response.time_windows;
      var optionsAsString = "";
      for (var i = 0; i < deliveryWindowArray.length; i++) {
        optionsAsString +=
            "<option value='" +
            deliveryWindowArray[i] +
            "'>" +
            deliveryWindowArray[i] +
            "</option>";
      }
      // $('#booking_pickup_window').addClass("nice-select nice-selector");
      $(".delivery_window_admin option").remove();
      $(".delivery_window_admin").append(
          "<option value>Select</option>" + optionsAsString
      );
      App.Common.loadNiceSelect("update");
    });
  }

  additional_pickup_window() {
    var pickup_window = this.addpickupSelectedWindowTarget.value.split("-")[0];
    this.getDeliverTimeWindows(pickup_window).then((response) => {
      var deliveryWindowArray = response.time_windows;
      var optionsAsString = "";
      for (var i = 0; i < deliveryWindowArray.length; i++) {
        optionsAsString +=
            "<option value='" +
            deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "'>" +
            deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
            "</option>";
      }
      // $('#booking_pickup_window').addClass("nice-select nice-selector");
      $("#booking_delivery_window option").remove();
      $("#booking_delivery_window").append(
          "<option value>Select</option>" + optionsAsString
      );
      App.Common.loadNiceSelect("update");
    });
  }

  getDeliverTimeWindows(selected_pickup_window) {
    return $.ajax({
      url: "/delivery_time_windows_info",
      dataType: "json",
      type: "GET",
      data: { selected_pickup_window: selected_pickup_window },
    });
  }

  search_bookings() {
    if (this.queryTarget) {
      search["query"] = this.queryTarget.value;
    }
    $.ajax({
      url: "/bookings",
      type: "GET",
      dataType: "script",
      data: search,
    });
  }

  operation_search_bookings() {
    updateDateRange($("#reportrange").val());

    if (this.transportmodesTargets) {
      booking_search["modes"] = [];
      this.transportmodesTargets.map((t) => {
        if (t.checked) {
          booking_search["modes"].push(t.value);
        }
      });
    }

    if (this.citiesTargets) {
      booking_search["city_ids"] = [];
      this.citiesTargets.map((t) => {
        if (t.checked) {
          booking_search["city_ids"].push(t.value);
        }
      });
    }

    if (this.pickupstatusesTargets) {
      booking_search["pickup_status"] = [];
      this.pickupstatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["pickup_status"].push(t.value);
        }
      });
    }

    if (this.deliverystatusesTargets) {
      booking_search["delivery_status"] = [];
      this.deliverystatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["delivery_status"].push(t.value);
        }
      });
    }

    if (this.invoicestatusesTargets) {
      booking_search["invoice_status"] = [];
      this.invoicestatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["invoice_status"].push(t.value);
        }
      });
    }

    if (this.queryTarget) {
      booking_search["query"] = this.queryTarget.value;
    }

    $.ajax({
      url: "/bookings/operations_list_view",
      type: "GET",
      dataType: "script",
      data: booking_search,
    });
  }

  assign_driver_auto(ev) {
    let addpickup = $("#additional_pickup");
    let hasaddp =
        addpickup.is(":visible") && addpickup.find("input").length > 0;
    let isfirst = $(ev.currentTarget).closest("#pickup").length > 0;
    let choices = ".assignment input[type='radio']:first";
    $("#booking_auto_assign").val("true");
    if (isfirst) {
      // clicked on first picked
      if (hasaddp) {
        // has additional pickup
        withinEL($(this.availableDriversTargets), "#pickup").empty();
      } else {
        // hasonly delivery
        $(this.availableDriversTargets).empty();
        $("#delivery, #pickup").find(choices).prop("checked", true);
      }
    } else {
      // clicked on delivery/additional
      if (hasaddp) {
        // has additional
        withinEL($(this.availableDriversTargets), "#delivery").empty();
        $(this.showAvailableDriversTargets).empty();
        $("#delivery, #additional_pickup").find(choices).prop("checked", true);
      } else {
        // on delivery
        $(this.availableDriversTargets).empty();
        $("#delivery, #pickup").find(choices).prop("checked", true);
      }
    }
  }

  update_driver_manually(ev, data) {
    let addpickup = $("#additional_pickup");
    let hasaddp =
        addpickup.is(":visible") && addpickup.find("input").length > 0;
    let isfirst = $(ev.target).closest("#pickup").length > 0;
    let self = this,
        choices = ".assignment input[type='radio']:last";

    if (isfirst) {
      if (hasaddp) {
        withinEL($(self.availableDriversTargets), "#pickup").html(data.body);
      } else {
        $(self.availableDriversTargets).html(data.body);
        $("#delivery,#pickup").find(choices).prop("checked", true);
      }
    } else {
      if (hasaddp) {
        // has additional
        // debugger;
        withinEL(
            $(self.showAvailableDriversTargets),
            "#additional_pickup"
        ).html(data.body);
        withinEL($(self.availableDriversTargets), "#delivery").html(data.body);
        withinEL($(self.availableDriversTargets), "#delivery").removeClass(
            "d-none"
        );
        $("#delivery,#additional_pickup").find(choices).prop("checked", true);
        withinEL($(self.selectedDriverTargets), "#delivery").addClass("d-none");
        withinEL($(self.assignmentCheckboxesTargets), "#delivery").removeClass(
            "d-none"
        );
        withinEL($(self.searchDriversTargets), "#delivery").removeClass(
            "d-none"
        );
      } else {
        // on delivery
        $(self.availableDriversTargets).html(data.body);
        $("#delivery,#pickup").find(choices).prop("checked", true);
      }
    }
  }

  assign_driver_manually(ev) {
    var self = this;
    $("#booking_auto_assign").val("false");
    $.ajax({
      url: "/bookings/get_drivers_info",
      type: "GET",
      dataType: "json",
      success: function (data) {
        self.update_driver_manually(ev, data);
      },
    });
  }

  additional_assign_driver_manually(ev) {
    var additional_pickup =
        this.additionaldriversearchTarget.dataset.additional;
    var self = this;
    $.ajax({
      url: "/bookings/additional_drivers_info",
      type: "GET",
      data: { additional_pickup: additional_pickup },
      dataType: "json",
      success: function (data) {
        self.update_driver_manually(ev, data);
      },
    });
  }

  additional_driver_assignment() {
    var id;
    this.driverIdTargets.map((t) => {
      if (t.checked) {
        id = t.value;
      }
    });
    // debugger;
    this.selectedDriverTargets[1].id = "additional-driver";
    $(this.assignmentCheckboxesTargets).addClass("d-none");
    this.additionalTransporterTarget.classList.add("d-none");

    this.showAvailableDriversTargets.map((t) => {
      t.classList.add("d-none");
    });
    $(this.availableDriversTargets).addClass("d-none");

    this.showSelectedDriverTargets.map((t) => {
      t.classList.remove("d-none");
    });
    $(this.selectedDriverTargets).removeClass("d-none");
    $.ajax({
      url: `/bookings/${id}/additional_driver_info`,
      type: "GET",
    });
  }

  driver_assignment() {
    var id;
    this.driverIdTargets.map((t) => {
      if (t.checked) {
        id = t.value;
      }
    });

    $(this.assignmentCheckboxesTargets).addClass("d-none");
    $(this.searchDriversTargets).addClass("d-none");
    $(this.availableDriversTargets).addClass("d-none");
    $(this.selectedDriverTargets).removeClass("d-none");

    $.ajax({
      url: `/bookings/${id}/get_driver_info`,
      type: "GET",
    });
  }

  invoice_driver_assignment() {
    var id;
    this.driverIdTargets.map((t) => {
      if (t.checked) {
        id = t.value;
      }
    });
    $(this.selectedDriverTargets).removeClass("show");
    $.ajax({
      url: `/bookings/${id}/get_driver_info`,
      type: "GET",
      data: { invoice: "invoice" },
    });
  }

  return_transporters(ev) {
    if (ev !== undefined) ev.stopPropagation();

    $(this.assignmentCheckboxesTargets).removeClass("d-none");
    $(this.searchDriversTargets).removeClass("d-none");
    $(this.availableDriversTargets).removeClass("d-none");
    $(this.selectedDriverTargets).addClass("d-none");
    if ($(this.currentDriverIdTargets).val()) {
      $(this.currentDriverIdTargets).val("");
    }
  }

  return_additional_transporters(ev) {
    ev.stopPropagation();

    $(this.assignmentCheckboxesTargets).removeClass("d-none");
    $(this.searchDriversTargets).removeClass("d-none");
    $(this.availableDriversTargets).removeClass("d-none");
    $(this.selectedDriverTargets).addClass("d-none");
    $(this.showAvailableDriversTarget).removeClass("d-none");
    $(this.additionalTransporterTarget).removeClass("d-none");
    $(this.showSelectedDriverTargets).addClass("d-none");
    if ($(this.additionalcurrentDriverIdTargets).val()) {
      $(this.additionalcurrentDriverIdTargets).val("");
    }
  }

  edit_driver_assignment() {
    var id;
    this.driverIdTargets.map((t) => {
      if (t.checked) {
        id = t.value;
      }
    });
    this.availableDriversTarget.classList.add("d-none");
    this.selectedDriverTarget.classList.remove("d-none");
    $.ajax({
      url: `/bookings/${id}/get_driver_info`,
      type: "GET",
    });
  }

  setCurrentTargetValue(id) {
    const ctrl = $("#taskdetail").get(0).bookings;
    ctrl.tasksInfoTarget.dataset.id = id;
  }
  task_info() {
    var id = this.tasksInfoTarget.dataset.id;
    if (
        $("#task-detailed-info").is(":visible") &&
        $("#taskdetail").is(":visible")
    ) {
      console.log("both are visible");
      this.setCurrentTargetValue(id);
    }
    $.ajax({
      url: "/bookings/task_details",
      type: "GET",
      dataType: "script",
      data: { id: id },
    });
  }
  setAllBookingFilters() {
    const param = window.location.search.substring(1);
    let str = decodeURIComponent(param.replace(/&/g, ",").replace(/=/g, ":"))
        .replace(/[[\]]/g, "")
        .split(",");
    console.log(str);
    const obj = str.reduce(
        (acc, cr) => {
          let [key, value] = cr.split(":");
          console.log(cr, key, typeof value);
          console.log(acc);
          if (key === "query" || key === "start_date" || key === "end_date") {
            acc[key] = value;
          } else {
            acc[key] = [...acc[key], value];
          }
          return acc;
        },
        {
          query: "",
          start_date: "",
          end_date: "",
          city_ids: [],
          pickup_status: [],
          delivery_status: [],
          modes: [],
        }
    );
    for (let key in obj) {
      booking_search[key] = obj[key];
    }
    console.log(booking_search);
    this.transportmodesTargets.map((t) => {
      if (booking_search["modes"].includes(t.value)) {
        t.checked = true;
      }
    });
    this.citiesTargets.map((t) => {
      if (booking_search["city_ids"].includes(t.value)) {
        t.checked = true;
      }
    });
  }
}

var updateDateRange = function (value) {
  var parts = value.split("-");
  if (parts.length > 1) {
    var start_date = parts[0];
    var end_date = parts[1];
    booking_search["start_date"] = start_date;
    booking_search["end_date"] = end_date;
    console.log("filter daterange", start_date, end_date);
  }
};

var withinEL = function (element, selector) {
  return element.filter(function () {
    return $(this).closest(selector).length;
  });
};

$(function () {
  var date = $("#reportrange");
  date.on("change", function (e) {
    updateDateRange(e.target.value);
    $.ajax({
      url: "/bookings/operations_list_view",
      type: "GET",
      dataType: "script",
      data: booking_search,
    });
  });
});

$(function () {
  var date = $("#historydate");
  if (window.location.pathname === "/bookings") {
    console.log(moment());
    date.daterangepicker({
      startDate: moment().subtract(7, "days"),
      locale: {
        format: "D MMM YYYY",
      },
      endDate: moment().add(7, "days"),
      ranges: {
        All: ["5-01-2015", moment().add(365, "days")],
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
    });
  }
  date.on("change", function (e) {
    var selected_date = e.target.value.split("-");
    var start_date = selected_date[0];
    var end_date = selected_date[1];
    booking_search["start_date"] = start_date;
    booking_search["end_date"] = end_date;
    console.log("History", start_date, end_date);
    $.ajax({
      url: "/bookings",
      type: "GET",
      dataType: "script",
      data: booking_search,
    });
  });
});

$(function () {
  $("[data-id='delivery_address']").on("input", function () {
    if ($(this).val() == "") {
      $(`#${$(this).data("id")}`).css("display", "none");
    }
  });
});

$(function () {
  $("[data-id='pickup_address']").on("input", function () {
    if ($(this).val() == "") {
      $(`#${$(this).data("id")}`).css("display", "none");
    }
  });
});

$(function(){
  $(document).on("click",".pickup_window",function(){
    var sDate = $("[name='booking[pickup_delivery_date]']").val();
    if(sDate == ""){
      $(this).find(".list").hide();
      let errors = `<p style='color:#4E5157;font-weight:400;' class='mb-0 text-center'>Please select a 
        Pickup and Delivery Date before </br> selecting the pickup window.</p>`
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
        confirmButtonText: "Close"
      })
    }
  })
  $(document).on("click",".delivery_window",function(){
    var pickup_window = $("[name='booking[pickup_window]']").val();
    if(pickup_window == ""){
      $(this).find(".list").hide();
      let errors = `<p style='color:#4E5157;font-weight:400;' class='mb-0 text-center'>Please select the
      Pickup Window before selecting </br> the Delivery Window.</p>`;
      Swal.fire({
        imageUrl: warning,
        html: errors,
        confirmButtonClass: 'btn btn-primary btn-block w-100 shadow-none',
        confirmButtonText: 'Close'
      })
    }
  })
})

$(function(){
  var  pickupWindowArray = $('.admin_pickup_window option').toArray().map(o=>o.value);
  var optionsAsString = "";
  for(var i = 1; i < pickupWindowArray.length; i++){
    optionsAsString +=
        "<option value='" +
        pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
        "'>" +
        pickupWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
        "</option>";
  }
  $(".admin_pickup_window  option").remove();
  $(".admin_pickup_window").append(
      "<option value>Select</option>" + optionsAsString
  );
  $('.admin_pickup_window').val($('#booking_hidden_pickup_window').val());
  App.Common.loadNiceSelect("update");
})

$(function(){
  var deliveryWindowArray = $('.admin_delivery_window option').toArray().map(o=>o.value);
  var optionsAsString = "";
  for(var i = 1; i < deliveryWindowArray.length; i++){
    optionsAsString +=
        "<option value='" +
        deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
        "'>" +
        deliveryWindowArray[i].replace(/^0+/g, "").replace(/\s0+/g," ") +
        "</option>";
  }
  $(".admin_delivery_window  option").remove();
  $(".admin_delivery_window").append(
      "<option value>Select</option>" + optionsAsString
  );
  $('.admin_delivery_window').val($('#booking_hidden_delivery_window').val());
  App.Common.loadNiceSelect("update");
})

$(function(){
  $(document).on("click",".favourite_locations",function(){
    var fav_locations = $(this).find(".list .option");
    console.log(fav_locations)
    if(fav_locations.length <= 1){
      $(this).find(".list").hide();
      let error = `<p style='color:#4E5157;font-weight:400' class='mb-0 text-center'>You don't
      have any favourite locations saved.</br>Add favourite locations via the side menu.</p>`
      Swal.fire({
        imageUrl: warning,
        html: error,
        confirmButtonClass: 'btn btn-primary btn-block w-100 shadow-none',
        confirmButtonText: 'Close'
      })
    }
  })
})

$(function(){
  $('.pickup-window-view').each(function(){
    var pickup_window_text = $(this).text().replace(/^0+/g,"").replace(/\s0+/g," ");
    $(this).text(pickup_window_text)
  })
  $('.delivery-window-view').each(function(){
    var delivery_window_text = $(this).text().replace(/^0+/g,"").replace(/\s0+/g," ");
    $(this).text(delivery_window_text);
  })
})