import { CheckForTheDriver, onRenderDriverOnMap } from './base';


export default function (map, layerId, newGeometry, mode) {

  // Update point geometry to a new position based on counter denoting
  // the index to access the arc.
  console.log('Tracking logic default func');
  let { data, driverIndex } = CheckForTheDriver({ map, dataSource: 'mainSource', id: layerId });
  let { geometry: oldGeometery } = data.features[driverIndex];

  console.log("trackingLogic", newGeometry, oldGeometery);
  let steps1 = 0;
  let numDelta = 50;
  let position = oldGeometery.coordinates;
  let newLocation = newGeometry.coordinates;
  let lng = newLocation[0] - position[0];
  let lat = newLocation[1] - position[1];
  let deltaLng = lng / numDelta
  let deltaLat = lat / numDelta;
  let angle = turf.rhumbBearing(oldGeometery, newGeometry);

  return function name() {
    console.log(mode, 'for this tracking mode');
    console.count();

    position[0] += deltaLng;
    position[1] += deltaLat;
    const realTimeDataOfDriver = map.getSource('mainSource');
    if (realTimeDataOfDriver._data.features.length !== data.features.length) {
      let { data, driverIndex } = CheckForTheDriver({ map, dataSource: 'mainSource', id: layerId });
      data.features[driverIndex].geometry.coordinates = position;
      if (mode === 'bicycle' || mode === 'walking') {
        data.features[driverIndex].properties.bearing = 0;
      } else {
        data.features[driverIndex].properties.bearing = angle;
      }
      onRenderDriverOnMap(map, data);
    } else {
      data.features[driverIndex].geometry.coordinates = position;
      if (mode === 'bicycle' || mode === 'walking') {
        data.features[driverIndex].properties.bearing = 0;
      } else {
        data.features[driverIndex].properties.bearing = angle;
      }
      // data.features[driverIndex].properties.bearing = angle;
      onRenderDriverOnMap(map, data);
    }


    // map.getSource('mainSource').setData(data);

    // map.setLayoutProperty(`driver${layerId}layer`, "icon-rotate", angle);

    // map.getSource(`driver${layerId}layer`).setData({
    //   type: "FeatureCollection",
    //   features: [
    //     {
    //       type: "Feature",
    //       properties: {},
    //       geometry: { type: "Point", coordinates: position },
    //     },
    //   ],
    // });

    if (steps1 != numDelta) {
      steps1++;
      requestAnimationFrame(name);
    }
  }
}
