import { Controller } from "stimulus";
const search = { search: "", statuses: [] };
const performance_search = { query: "", start_date: "", end_date: ""}
export default class extends Controller {
  static targets = [
    "search", 
    "status", 
    "editBtn", 
    "submitBtn", 
    "form", 
    "modalTitle",
    "selectedDateRange",
    "userId",
    "performancequery"
  ];

  user_performance_data() {
    console.log("testing");
   var selected_date = this.selectedDateRangeTarget.value.split("-")
   var start_date = selected_date[0]
   var end_date = selected_date[1]
   var id = this.selectedDateRangeTarget.dataset.user_id
    $.ajax({
      url: `/admin/users/${id}/user_performance`,
      type: "GET",
      dataType: "script",
      data: { start_date: start_date, end_date: end_date }
    });   
  }

  search_user(e) {
    if (this.statusTargets) {
      search["statuses"] = [];
      this.statusTargets.map(t => {
        if (t.checked) {
          if (t.value == "1") {
            search["statuses"].push(t.value, "5");
          } else {
            search["statuses"].push(t.value);
          }
        }
      });
    }
    if (this.searchTarget) {
      search["search"] = this.searchTarget.value;
    }
    $.ajax({
      url: "/admin/users",
      type: "GET",
      dataType: "script",
      data: search
    });
  }

  search_user_performance(e){
    updateDateRange($("#userreport").val())
    if(this.hasPerformancequeryTarget){
      performance_search["query"] = this.performancequeryTarget.value
    }
    $.ajax({
      url: "users_performance",
      type: "GET",
      dataType: "script",
      data: performance_search
    })
  }
}
var updateDateRange = function(value){
  var parts = value.split("-")
  if(parts.length > 1){
    var start_date = parts[0];
    var end_date = parts[1];
    performance_search["start_date"] = start_date;
    performance_search["end_date"] = end_date;
    console.log("filter daterange", start_date, end_date);
  }
}

$(function(){
  var date = $("#userreport")
  date.on("change",function(e){
    updateDateRange(e.target.value)
    $.ajax({
      url: "users_performance",
      type: "GET",
      dataType: "script",
      data: performance_search
    })
  })
})

$(document).on('turbolinks:load',function(){
  $(function(){
   $("#user-performance").val(performance_search["query"]);
    if(performance_search["start_date"] && performance_search["end_date"]){
      $("#userreport").val(performance_search["start_date"]+"-"+performance_search["end_date"])
    }
  })
  $(function(){
    $("#user-per-xl").on("click",function(){
      var query = performance_search["query"]
      var start_date = performance_search["start_date"]
      var end_date = performance_search["end_date"]
      $("#user-per-xl").attr("href","/admin/users/users_performance.xlsx?query="+query+"&start_date="+start_date+"&end_date="+end_date+"");
    })
  })
})