import moment from "moment";
// import TrackingLogin from './trackingLogic';
$(function () {
  // GENERAL FUNCTION FOR MAP INTEGRATION
  if (sessionStorage.getItem("oldCoor") || sessionStorage.getItem("ETA")) {
    sessionStorage.clear();
  }
  async function maptracking({
    container,
    pickupDestinationCoor,
    driverId,
    taskType,
  }) {
    //  retrive the current location of the driver
    // let driverCurrentCoordinates;
    mapboxgl.accessToken =
      "pk.eyJ1IjoidHJhbnNwb3JhIiwiYSI6ImNqeTYyenY5dzBjdnEzY3IyNDlkNmg2dHQifQ.W_d3WysAFREko_F9p1XtFA";
    let map = new mapboxgl.Map({
      container, // container id
      style: "mapbox://styles/transpora/ckduac8ek0z8k19pmlz9jxdv0", //hosted style id
      center: pickupDestinationCoor, // starting position
      maxZoom: 16,
      zoom: 12, // starting zoom
    });
    map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();
    // Check the current status
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );
    // map.loadImage(require("../../assets/images/car-offline.png"), function (
    //   error,
    //   image
    // ) {
    //   if (error) throw error;
    //   map.addImage(`car`, image);
    // });
    let reloadTimer;
    let timer;
    let countDownCounter = null;
    let callApiCounter = null;
    let currentCoor;

    function pageRelaodOnStatus() {
      console.log($("#task-id").html().trim());
      $.ajax({
        url: "/driver_status",
        type: "GET",
        data: {
          id: $("#task-id").html().trim(),
        },
        dataType: "text",
        success: (driverStatus) => {
          console.log(driverStatus);
          // console.log(window.location);
          if (driverStatus !== "arrived" && driverStatus !== "started") {
            clearInterval(timer);
            clearInterval(reloadTimer);
            if (countDownCounter !== null) {
              clearInterval(countDownCounter);
              countDownCounter = null;
            }
            if (callApiCounter !== null) {
              clearTimeout(callApiCounter);
            }

            window.location.reload();
            return;
          }
        },
        error: (err) => {
          console.log(err, "EROOR>>>>><<<<<<");
        },
      });
      // clearInterval(reloadTimer);
    }
    reloadTimer = setInterval(pageRelaodOnStatus, 5000);

    // function for the format of the timer

    function timerFormat({ hour, sec, min }) {
      let renderString = ``;

      if (hour > 0) {
        renderString = `${
          (hour > 0 && hour > 1 && hour + " hrs ") || hour + " hr " || ""
        }${(min > 0 && min > 1 && min + " mins") || min + " min" || ""}`;
      } else {
        renderString = `${
          (min > 0 && ((min > 1 && min + " mins ") || min + " min ")) ||
          `< 1 min `
        }`;
      }
      $(".estimated-timing").html(renderString);
    }

    // Function to find the ETA
    async function findingCurrentETA({
      geometry: { coordinates },
      properties: { mode },
    }) {
      let end = pickupDestinationCoor;
      console.log(coordinates);

      // const url = `https://api.mapbox.com/directions/v5/mapbox/driving/-122.6619,45.523729;-122.670917,45.526882?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`;
      let drivingMode;
      if (mode === "walking") {
        drivingMode = "walking";
      } else if (mode === "bicycle") {
        drivingMode = "cycling";
      } else {
        drivingMode = "driving-traffic";
      }
      const url = `https://api.mapbox.com/directions/v5/mapbox/${drivingMode}/${coordinates[0]},${coordinates[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`;

      try {
        let durationData = await fetch(url).then((data) => data.json());
        console.log(durationData, "Duration data");
        if (durationData.routes.length > 0) {
          let {
            routes: [{ duration }],
          } = durationData;
          console.log(duration, "this is the current ETAAAA");
          sessionStorage.setItem("currentTime", moment().format("H:m:s"));
          sessionStorage.setItem("ETA", duration);
          // if (sessionStorage.getItem("ETA")) {
          // let duration = JSON.parse(sessionStorage.getItem("ETA"));
          if (duration) {
            const [hour, min, sec] = moment()
              .startOf("day")
              .seconds(duration)
              .format("H:m:s")
              .split(":");
            const time = moment().add({ h: hour, m: min }).format("h:mm A");
            if (time) {
              $(".final-time").html(` (${time})`);
            }
            // timerFormat({ hour, time });

            if (callApiCounter !== null) {
              clearTimeout(callApiCounter);
              callApiCounter = null;
            }
            registerTimerForApiCall(min);
            if (countDownCounter !== null) {
              clearInterval(countDownCounter);
              countDownCounter = null;
            }

            countDownTimer();
          }
          // }
        }
      } catch (err) {
        console.log(err);
      }
    }

    function registerTimerForApiCall(min) {
      if (min >= 21) {
        callApiCounter = setTimeout(() => {
          console.log("are you runing after the 2 min");
          findingCurrentETA({ geometry: { coordinates: currentCoor } });
        }, 600000);
      } else {
        callApiCounter = setTimeout(() => {
          console.log("are you runing after the 1 min");

          findingCurrentETA({ geometry: { coordinates: currentCoor } });
        }, 300000);
      }
    }

    // countdown timer for the ETA

    function countDownTimer() {
      function name(secs) {
        // let minutes,seconds;
        // let timer = min;
        const [hour, min, sec] = moment()
          .startOf("day")
          .seconds(secs)
          .format("H:m:s")
          .split(":");
        const finals = moment().add({ h: hour, m: min, s: sec }).format();
        // console.log(time, 'this is time moment');
        let final = new Date(finals).getTime();

        countDownCounter = setInterval(() => {
          var now = new Date().getTime();
          var timeleft = final - now;

          // var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
          var hours = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
          console.log(hours, minutes, seconds);
          if (timeleft < 0) {
            clearInterval(countDownCounter);
            countDownCounter = null;
            return;
          }
          timerFormat({ hour: hours, min: minutes, sec: seconds });
        }, 1000);
      }

      if (sessionStorage.getItem("ETA")) {
        let actualEta = sessionStorage.getItem("ETA");
        console.log("hii");

        name(actualEta);
      }
    }

    // countDownCounter = setInterval(() => {
    //   if (sessionStorage.getItem('ETA')) {
    //     clearInterval(countDownCounter);
    //     countDownTimer();
    //   }
    // }, 1000);

    // INITIALIZE ALL THE VARIABLE

    let isMapDragged = false;

    //  function for the driveCar
    function driveCar(
      { coordinates: oldCord },
      { coordinates: newCord },
      driverDetail
    ) {
      console.log(oldCord, newCord, driverDetail);
      let numDeltas = 50;
      let steps = 0;
      let deltaLat = 0;
      let deltaLng = 0;
      let position = [0, 0];
      let newLocation = [];
      let angle = 0;

      // steps = 0;
      position = [...oldCord];
      newLocation = [...newCord];
      let lng = newLocation[0] - oldCord[0];
      let lat = newLocation[1] - oldCord[1];
      deltaLng = lng / numDeltas;
      deltaLat = lat / numDeltas;
      let pt1 = turf.point(newLocation);
      let pt2 = turf.point(oldCord);
      angle = turf.rhumbBearing(pt2, pt1);
      // let ptr1 = turf.point(newLocation);
      // let ptr2 = turf.point(JSON.parse(sessionStorage.getItem("oldCoor")));

      return function driveTransporter() {
        const data = map.getSource("transporter")._data;
        position[0] += deltaLng;
        position[1] += deltaLat;
        console.log(position);
        console.log("driver driving the car");
        console.log(data);
        data.geometry.coordinates = position;
        if (
          driverDetail.mode === "bicycle" ||
          driverDetail.mode === "walking"
        ) {
          data.properties.bearing = 0;
        } else {
          data.properties.bearing = angle;
        }
        map.getSource("transporter").setData(data);

        if (steps != numDeltas) {
          steps++;
          requestAnimationFrame(driveTransporter);
        }
      };
    }

    // Event handler for the map recenter button
    $("#tracking__map--recenter").on("click", function () {
      isMapDragged = false;

      map.flyTo({
        // pitch: angle,
        // bearing: angle - 180,
        center: currentCoor || [0, 0],
      });
    });

    async function getRoute(start) {
      console.log(start);
      currentCoor = start.geometry.coordinates;
      if (!sessionStorage.getItem("ETA")) {
        await findingCurrentETA(start);
      }
      // else if (sessionStorage.getItem("currentTime")) {
      //   let currentTime = moment().format("H:m:s");
      //   let pastTime = sessionStorage.getItem("currentTime");
      //   let min = moment
      //     .utc(
      //       moment(currentTime, "HH:mm:ss").diff(moment(pastTime, "HH:mm:ss"))
      //     )
      //     .format("mm");
      //   console.log(min);
      // }

      // let end = pickupDestinationCoor;
      // let angle;
      // let end = [-122.668978, 45.527768];
      //       var url = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${start[0]},${start[1]};${end[0]},${end[1]}
      // ?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`;
      try {
        // let {
        //   routes: [{ geometry }],
        // } = await fetch(url).then((data) => data.json());

        // geojson = {
        //   type: "Feature",
        //   properties: {},
        //   geometry,
        // };
        // console.log(geojson);
        // canvas.style.cursor = "";

        // console.log(
        //   geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1]
        // );
        // if (marker.getLngLat()) {
        //   angle = turf.bearing(
        //     turf.point(geojson.geometry.coordinates[0]),
        //     turf.point([marker.getLngLat().lng, marker.getLngLat().lat])
        //   );
        //   marker.setRotation(angle - map.getBearing());
        // } else {
        //   angle = turf.bearing(
        //     turf.point(geojson.geometry.coordinates[0]),
        //     turf.point(
        //       geojson.geometry.coordinates[
        //         geojson.geometry.coordinates.length - 1
        //       ]
        //     )
        //   );
        //   marker.setRotation(angle - 90);
        // }
        // console.log(marker.getLngLat(),'This is the latlng');
        // console.log([marker.getLngLat().lng], [marker.getLngLat().lat]);
        // console.log(
        //   angle,
        //   "From the fecth angle",
        //   geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1],
        //   "From the fectch coordinates"
        // );
        // console.log(angle);
        // console.log(map.getBearing());
        // marker.setLngLat(
        //   // geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1]
        //   // geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1]
        //   // geojson.geometry.coordinates[0]
        //   start
        // );
        // marker.addTo(map);
        // map.jumpTo({ center: start });
        // map.jumpTo({ center: geojson.geometry.coordinates[0] });

        // if (map.getSource("route")) {
        //   map.getSource("route").setData(geojson);
        //   // add turn instructions here at the end
        // } else {
        //   map.addLayer({
        //     id: "route",
        //     type: "line",
        //     source: {
        //       type: "geojson",
        //       data: geojson,
        //     },
        //     layout: {
        //       "line-join": "round",
        //       "line-cap": "round",
        //     },
        //     paint: {
        //       "line-color": "#3887be",
        //       "line-width": 5,
        //       "line-opacity": 0.75,
        //     },
        //   });
        // }

        // coor = start.geometry.coordinates;

        // console.log(sessionStorage.getItem("oldCoor"));

        if (map.getLayer("transporter")) {
          console.log(map.getSource("transporter"));
          const { geometry: oldCord } = map.getSource("transporter")._data;
          console.log(
            turf.booleanEqual(start.geometry, oldCord),
            "this is point"
          );

          console.log(start.geometry, oldCord);

          if (!turf.booleanEqual(start.geometry, oldCord)) {
            driveCar(oldCord, start.geometry, start.properties)();
          }
          // console.log(turf.booleanEqual(ptr1, ptr2));
          // console.log(
          //   turf.Equal(ptr1, ptr2),
          //   "Here we match the new point that we are always done"
          // );

          // Start of the comment
          // map.getSource("cars").setData({
          //   type: "Feature",
          //   properties: {},
          //   geometry: {
          //     type: "Point",
          //     coordinates: start,
          //   },
          // });
          // console.log("hello how are you this is source");
          // map.getSource("cars").setData(start);
          // map.setLayoutProperty("car", "icon-rotate", angle - map.getBearing());
          if (!isMapDragged) {
            map.easeTo({
              // pitch: angle,
              // bearing: angle - 180,
              center: start.geometry.coordinates,
              duration: 2000,
            });
          }
        } else {
          start.properties.bearing = 0;
          // start.properties.mode = 'car';
          console.log(start);
          map.addLayer({
            id: "transporter",
            type: "symbol",
            source: {
              type: "geojson",
              data: start,
            },
            layout: {
              "icon-image": ["concat", ["get", "mode"], "-tracking"],
              "icon-size": 1,
              "icon-rotate": ["get", "bearing"],
            },
          });

          // let el = document.createElement("div");
          // let img = document.createElement("img");
          // img.setAttribute(
          //   "src",
          //   require("../../assets/images/Test Car Icon.svg")
          // );
          // el.className = "car_marker";
          // el.appendChild(img);

          // marker = new mapboxgl.Marker(el);
          // marker.setLngLat(coor);
          // marker.addTo(map);
          // angle = turf.bearing(turf.point(coor), turf.point(coor));
          // sessionStorage.setItem("oldCoor", JSON.stringify(coor));
          // marker.setRotation(angle - 180);
          // map.easeTo({
          //   // pitch: angle,
          //   // bearing: angle - 180,
          //   center: coor,
          //   duration: 2000,
          // });
          // Start of the comment
          // map.loadImage(
          //   require("../../assets/images/testCarIcon.png"),
          //   function (error, image) {
          //     if (error) throw error;
          //     map.addImage("cat", image);

          //     map.addSource("cars", {
          //       type: "geojson",
          //       data: start,
          //     });
          //     map.addLayer({
          //       id: "car",
          //       type: "symbol",
          //       source: "cars",
          //       layout: {
          //         "icon-image": "cat",
          //         "icon-size": 0.5,
          //         "icon-rotate": angle - map.getBearing(),
          //       },
          //       // paint: {
          //       //   "circle-radius": 10,
          //       //   "circle-color": "red",
          //       // },
          //     });
          //   }
          // );
          // map.easeTo({
          //   // pitch: angle,
          //   // bearing: angle - 180,
          //   center: coor,
          //   duration: 1000,
          // });

          // End of the car image car

          // map.addSource("cars", {
          //   type: "geojson",
          //   data: {
          //     type: "FeatureCollection",
          //     features: [
          //       {
          //         type: "Feature",
          //         properties: {},
          //         geometry: {
          //           type: "Point",
          //           coordinates: start,
          //         },
          //       },
          //     ],
          //   },
          // });
          // map.addLayer({
          //   id: "car",
          //   type: "circle",
          //   source: "cars",
          //   paint: {
          //     "circle-radius": 10,
          //     "circle-color": "red",
          //   },
          // });
        }
        // sessionStorage.setItem("oldCoor", JSON.stringify(coor));
      } catch (err) {
        console.log(err);
      }
    }

    map.on("load", function () {
      // make an initial directions request that
      // starts and ends at the same location
      // map.touchZoomRotate.enable();
      // map.touchZoomRotate.isActive();

      // Add starting point to the map
      map.addLayer({
        id: "point",
        type: "symbol",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: pickupDestinationCoor,
                },
              },
            ],
          },
        },
        layout: {
          "icon-image": taskType,
        },
      });
      // map.addLayer({
      //   id: "point",
      //   type: "circle",
      //   source: {
      //     type: "geojson",
      //     data: {
      //       type: "FeatureCollection",
      //       features: [
      //         {
      //           type: "Feature",
      //           properties: {},
      //           geometry: {
      //             type: "Point",
      //             coordinates: pickupDestinationCoor,
      //           },
      //         },
      //       ],
      //     },
      //   },
      //   paint: {
      //     "circle-radius": 10,
      //     "circle-color": "#3887be",
      //   },
      // });
      map.on("dragstart", function () {
        isMapDragged = true;
      });
      // map.addLayer({
      //   id: "route",
      //   type: "line",
      //   source: {
      //     type: "geojson",
      //     data: geojson
      //   },
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "round"
      //   },
      //   paint: {
      //     "line-color": "#3887be",
      //     "line-width": 5,
      //     "line-opacity": 0.75
      //   }
      // });

      // console.log(geojson, "--------");

      //   marker.setLngLat(
      //     geojson.geometry.coordinates[0]
      //   );

      // marker.setRotation(bearing - map.getBearing());
      // marker.addTo(map);
      // map.jumpTo({ center: pickupDestinationCoor });
      // map.zoomTo(15, { duration: 9000 });
    });

    timer = setInterval(checkForTheCoordinates, 3000);

    async function checkForTheCoordinates() {
      try {
        let data = await fetch(
          "https://7t4d76y43j.execute-api.ap-southeast-2.amazonaws.com/dev/"
        );

        let newData = await data.json();

        console.log(newData, " this is data");
        if (newData) {
          const { features } = newData;
          const curretCoords = features.find(
            ({ properties: { id }, geometry: { coordinates } }) =>
              id === driverId ? coordinates : ""
          );
          console.log(curretCoords, "Current coordinates");
          if (curretCoords) {
            // let {
            //   geometry: { coordinates },
            // } = curretCoords;

            getRoute(curretCoords);
          } else {
            console.log("good bye");
            clearInterval(timer);
          }
        }
        //     if(!jQuery.isEmptyObject(data)){
        // const {features:[{geometry:{coordinates}}]} = data;
        // console.log(coordinates);

        // if(coordinates && indicator === 1){
        //  return coordinates;
        // }
        // indicator(newData);

        // }
        // console.log(data);
      } catch (err) {
        console.log(err);
        clearInterval(timer);
      }
      // clearInterval(timer);

      //   .then(data=>{

      // if(!jQuery.isEmptyObject(data)){
      // const {features:[{geometry:{coordinates}}]} = data;
      // console.log(coordinates);

      // if(coordinates){

      // }

      // }

      //   }).catch(err=>console.log(err,'This is error'))
    }
  }
  // Data for getting the lat long
  if ($(".map_tracking_details").length > 0) {
    const { latitude, longitude, status, driver, tasktype } = $(
      ".map_tracking_details"
    ).data();

    if (status === "started" || status === "arrived") {
      console.log(latitude, longitude, status, driver, tasktype.toLowerCase());
      maptracking({
        container: "tracking-map",
        // startCoordinates: [delivery_longitude, delivery_latitude],
        pickupDestinationCoor: [longitude, latitude],
        driverId: driver,
        taskType: `${tasktype.toLowerCase()}-tracking`,
      });
    }
  }
});
