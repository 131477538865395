import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "inactive",
    "hiddenfields",
    "cancelBtn",
    "editBtn",
    "submitBtn",
    "imageUpload",
    "passwordLabel"
  ];

  togglebtn() {
    this.inactiveTarget.classList.toggle("form-inactive");
    this.editBtnTarget.classList.toggle("d-none");
    this.passwordLabelTarget.innerHTML = "Current Password"
    this.hiddenfieldsTargets.map(i => {
      i.classList.toggle("d-none");
    });
  }
}
