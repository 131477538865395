import warning from "../images/warning.svg";

if ($.validator) {
  $.validator.addMethod(
    "regex",
    function(value, element, regexp) {
      console.log(this.optional);
      var re = new RegExp(regexp);
      return this.optional(element) || re.test(value);
    },
    "Please check your input."
  );

  $.validator.addMethod( "require_from_group", function( value, element, options ) {
    var $fields = $( options[ 1 ], element.form ),
      $fieldsFirst = $fields.eq( 0 ),
      validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
      isValid = $fields.filter( function() {
        return validator.elementValue( this );
      } ).length >= options[ 0 ];

    // Store the cloned validator for future validation
    $fieldsFirst.data( "valid_req_grp", validator );

    // If element isn't being validated, run each require_from_group field's validation rules
    if ( !$( element ).data( "being_validated" ) ) {
      $fields.data( "being_validated", true );
      $fields.each( function() {
        validator.element( this );
      } );
      $fields.data( "being_validated", false );
    }
    return isValid;
  }, $.validator.format( "Please select atleast one item." ) );
      // $.extend($.validator.messages, {
      //     require_from_group: $.format("'Please enter either username/ email address to recover password'/Please fill out at least {0} of these fields.")
      // });

}

var form_valid = false;

export function handleErrorPlacement(error, element) {
  if (
    element.attr("type") == "radio" ||
    element.attr("type") == "checkbox" ||
    element.attr("type") == "file"
  ) {
    var error_div = element
      .parent()
      .parent()
      .find(".error-holder");
    $(error_div).html(error);
  } else {
    element.parent().append(error);
  }
}

export const validation_rules = {
  signIn: {
    errorElement: "span",
    rules: {
      "user[email]": {
        required: true,
        email: true
      },
      "user[password]": {
        required: true
      }
    },
    messages: {
      "user[email]": "Enter valid email address",
      "user[password]": "Enter password"
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  signUp: {
    errorElement: "span",
    rules: {
      "user[first_name]": {
        required: true
      },
      "user[last_name]": {
        required: true
      },
      "user[email]": {
        required: true,
        email: true
      },
      "user[mobile_number]": {
        required: true
      },
      "user[mobile_country_code]": {
        required: true
      },
      "user[address]": {
        required: true
      },
      "user[password]": {
        required: true,
        //regex: "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      },
      "user[password_confirmation]": {
        required: true,
        equalTo: "#password"
      },
      "user[terms_and_conditions]": {
        required: true
      },
      "user[abn_acn_no]":{
        number: true,
        minlength: 9,
        maxlength: 11
      }
    },
    messages: {
      "user[first_name]": "Enter first name",
      "user[last_name]": "Enter last name",
      "user[email]": "Enter valid email address",
      "user[mobile_number]": "Enter valid mobile number",
      "user[password]": {
        required: "Enter password",
        //regex:
          //"Must contain minimum 8 characters: a lowercase letter, an uppercase letter, a digit"
      },
      "user[password_confirmation]": {
        required: "Enter password",
        equalTo: "The passwords do not match. Try again."
        //regex:
         // "Must contain minimum 8 characters: a lowercase letter, an uppercase letter, a digit"
      },
      "user[terms_and_conditions]": {
        required: "Please accept the terms of service to register"
      },
      "user[abn_acn_no]":{
        number: "Enter valid ABN/ACN",
        minlength: "Enter valid ABN/ACN",
        maxlength: "Enter valid ABN/ACN"
      }
    },
    errorPlacement: function(error, element) {
      console.log(error)
      handleErrorPlacement(error, element);
    }
  },
  forgotPassword: {
    errorElement: "span",
    rules: {
      "user[email]": {
        required: true
      }
    },
    messages: {
      "user[email]": "Enter your email"
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  confirmationForm: {
    errorElement: "span",
    rules: {
      "user[email]": {
        required: true
      }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  resetPassword: {
    errorElement: "span",
    rules: {
      "user[password]": {
        required: true
      },
      "user[password_confirmation]": {
        required: true,
        equalTo: "#new_password"
      }
    },
    messages: {
      "user[password]": "Enter password",
      "user[password_confirmation]": {
        required: "Enter password",
        equalTo: "The password do not match. Try again."
      }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  senderForm: {
    errorElement: "span",
    rules: {
      "user[first_name]": {
        required: true
      },
      "user[last_name]": {
        required: true
      },
      "user[email]": {
        required: true,
        email: true
      },
      "user[mobile_country_code]": {
        required: true
      },
      "user[mobile_number]": {
        required: true
      },
      "user[address]": {
        required: true
      },
      "user[abn_acn_no]": {
        number: true,
        minlength: 9,
        maxlength: 11,
      }
    },
    messages: {
      "user[abn_acn_no]": {
        number: "ABN/ACN is invalid",
        minlength: "ABN/ACN is invalid",
        maxlength: "ABN/ACN is invalid"
      }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },

  transporter: {
    errorElement: "span",
    ignore: ".ignored",
    debug: true,
    rules: {
      "user[first_name]": {
        required: true
      },
      "user[last_name]": {
        required: true
      },
      "user[abn_acn_no]": {
        required: true
      },
      "user[email]": {
        required: true,
        email: true
      },
      "user[mobile_country_code]": {
        required: true
      },
      "user[mobile_number]": {
        required: true
      },
      "user[address]": {
        required: true
      },
      "user[profile_attributes][personal_identities][]": {
        required: true
      },
      "user[profile_attributes][passport_birth_identities][]": {
        required: true
      },
      "user[profile_attributes][avatar]": {
        required: true
      },
      "user[profile_attributes][insurance_documents][]": {
        required: true
      },
      "user[profile_attributes][transport_mode]": {
        required: true
      },
      "user[profile_attributes][vehicle_model]": {
        required: true
      },
      "user[profile_attributes][vehicle_color]": {
        required: true
      },
      "user[profile_attributes][vehicle_registration_number]": {
        required: true
      },
      "user[profile_attributes][state_of_registration]": {
        required: true
      },
      "user[profile_attributes][bank_name]": {
        required: true
      },
      "user[profile_attributes][bsb_number]": {
        required: true
      },
      "user[profile_attributes][account_number]": {
        required: true
      },
      confirm_bank_account_number: {
        required: true,
        equalTo: "#account_number"
      },
      "user[profile_attributes][account_holder_name]": {
        required: true
      },
      "user[profile_attributes][dob]": {
        required: true
      },
      "user[profile_attributes][city_id]": {
        required: true
      },
      "user[status]": {
        required: true
      },
      "user[resubmission_reason]": {
        required: true
      }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },

  profile: {
    errorElement: "span",
    rules: {
      "user[first_name]": {
        required: true
      },
      "user[last_name]": {
        required: true
      },
      "user[email]": {
        required: true,
        email: true
      },
      "user[mobile_number]": {
        required: true
      },
      "user[address]": {
        required: true
      }
      // "user[current_password]": {
      //   required: true
      // },
      // "user[password]": {
      //   required: true
      // },
      // "user[password_confirmation]": {
      //   required: true,
      //   equalTo: "#password"
      // }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },

  city: {
    errorElement: "span",
    rules: {
      "city[name]": {
        required: true
      },
      "city[city_tag]": {
        required: true
      },  
      "city[latitude]": {
        required: true
      },
      "city[longitude]": {
        required: true
      }     
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },

  bookingForm: {
    errorElement: "span",
    ignore: "",
    debug: true,
    onfocusout: false,
    onclick: false,
    onkeyup: false,
    //onsubmit: true,
    groups: {
      names: "booking[booking_items_attributes][0][quantity] booking[booking_items_attributes][1][quantity] booking[booking_items_attributes][2][quantity]"
    },
    rules: {
        "booking[booking_items_attributes][0][quantity]": {
          require_from_group: [1, ".send"]
        },
        "booking[booking_items_attributes][1][quantity]": {
          require_from_group: [1, ".send"]
        },
        "booking[booking_items_attributes][2][quantity]": {
          require_from_group: [1, ".send"]
        }
    },    
    rules: {
      "booking[pickup_delivery_date]": {
        required: true
      },
      "booking[pickup_window]": {
        required: true
      },
      "booking[additional_pickup_window]": {
        required: true
      },      
      "booking[delivery_window]": {
        required: true
      },
      "booking[booking_tasks_attributes][0][name]": {
        required: true
      },
      "booking[booking_tasks_attributes][0][mobile_number]": {
        required: true,
        minlength: 10,
        maxlength: 10,
        digits: true
      },
      "booking[booking_tasks_attributes][0][address]": {
        required: true
      },
      "booking[booking_tasks_attributes][1][name]": {
        required: true
      },
      "booking[booking_tasks_attributes][1][mobile_number]": {
        required: true,
        minlength: 10,
        maxlength: 10,
        digits: true
      },
      "booking[booking_tasks_attributes][1][address]": {
        required: true
      },
      "booking[booking_items_attributes][1][item_type]": {
        required: true
      },
      "booking[booking_tasks_attributes][1][authority_to_leave]": {
        required: true
      },      
      "booking[barcodes][]": {
        required: true
      },
      "booking[adjustment]": {
        required: true
      },
      "booking[total_price]": {
        required: true
      },
      "booking[service_fee]": {
        required: true
      },
      "booking[manual_additional_driver]":{
        required: true
      },
      "booking[additional_driver_id]":{
        required: true
      }
    },
    messages: {
      "booking[manual_additional_driver]": {
        required: "Kindly select additional", 
      },
    },      
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    if(element.attr("name") == 'booking[pickup_window]' || element.attr("name") == 'booking[delivery_window]'){
      error.insertAfter(element.parent());
    }
    else if(element.attr("name") == 'booking[booking_tasks_attributes][1][authority_to_leave]'){
      error.insertAfter(element.parent().find('.error-field').append('</br>'));
    }
    },
    showErrors:function(errorMap,errorList){
      this.defaultShowErrors();
      if(errorList.length != 0){
        if(errorList.length == 1 && errorMap.hasOwnProperty("booking[manual_additional_driver]") || errorMap.hasOwnProperty("booking[additional_driver_id]")){
          let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Kindly select additional driver</p>`;
          Swal.fire({
            imageUrl: warning,
            html: errors,
            confirmButtonText: "Close",
            confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
            confirmButtonAriaLabel: "Close",
          });
        }
        else{
          let errors = `<p style='color:#4E5157;font-weight:400;' class="mb-0 text-center">Mandatory fields (with a red asterisk<span style='color:red'>&#42;</span>)
          have not </br> been completed. Please fill and try again.</p>`;
          Swal.fire({
            imageUrl: warning,
            html: errors,
            confirmButtonText: "Close",
            confirmButtonClass: "btn btn-primary btn-block w-100 shadow-none",
            confirmButtonAriaLabel: "Close",
          });
        }
      }
    }
  },
  favouriteLocationForm: {
    errorElement: "span",
    ignore: ".ignored",
    debug: true,
    rules: { 
      "favourite_location[favourite_location_name]": {
        required: true
      }, 
      "favourite_location[address]": {
        required: true
      }
    },             
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  coverageAreaForm: {
    errorElement: "span",
    rules: {
      "coverage_area[coverage_areas_csv]": {
        required: true
      }
    },
    errorPlacement: function(error, element) {
      handleErrorPlacement(error, element);
    }
  },
  /*addcardForm: {
    errorElement: "span",
    ignore: "",
    debug: true,
    rules: {
      cardHolderName: {
        required: true
      }
    },
    errorPlacement: function(error, element){
      console.log(error)
      handleErrorPlacement(error, element);
    }
  }*/
};
