import { Controller } from "stimulus";
const search = { query: "", search: "" };
const booking_search = {
  query: "",
  start_date: "",
  end_date: "",
  driver_ids: [],
  sender_id: [],
  pickup_status: [],
  delivery_status: [],
  invoice_status: []
};
export default class extends Controller {
  static targets = [
    "search",
    "query",
    "pickupstatuses",
    "deliverystatuses",
    "drivers",
    "senders",
    "invoicestatuses",
    "daterange"
  ];

  static values = {
    driverChecked: Boolean,
    senderChecked: Boolean
  }

  connect(){
    window.addDateRangePicker(this.daterangeTarget);
    let picker = $(this.daterangeTarget);
    picker.on("apply.daterangepicker", this.daterangeChanged.bind(this));
  }

  disconnect(){
    $(this.daterageTarget).data("daterangepicker").remove();
  }

  filter_by_drivers(ev){
    booking_search.driver_ids = []
    $("#all-transporters").find(".round input[type=checkbox]:checked").each(function(){
      if($(this).is(":checked")){
        booking_search.driver_ids.push(this.value);
      }
    })

    this.search_invoice_bookings().done(function(){
      $(ev.currentTarget).closest(".modal").modal("hide");
    });
  }

  filter_by_senders(ev){
    booking_search.sender_ids = []
    $("#all-senders").find(".round input[type=checkbox]:checked").each(function(){
      if($(this).is(":checked")){
        booking_search.sender_ids.push(this.value);
      }
    })

    this.search_invoice_bookings().done(function(){
      $(ev.currentTarget).closest(".modal").modal("hide");
    });
  }

  daterangeChanged(e){
    var selected_date = e.target.value.split("-");
    var start_date = selected_date[0];
    var end_date = selected_date[1];
    booking_search["start_date"] = start_date;
    booking_search["end_date"] = end_date;
    console.log("INVOICING", start_date, end_date);
    this.search_invoice_bookings();
  }

  search_transporters_invoice() {
    if (this.driversTarget) {
      search["query"] = this.driversTarget.value;
    }
    $.ajax({
      url: "/bookings/driver_search",
      type: "GET",
      dataType: "script",
      data: search,
    });
  }

  checkall_drivers(){
    this.driverCheckedValue = !this.driverCheckedValue
    $("#all_transporters").find("td .round input[type=checkbox]").prop("checked", this.driverCheckedValue);
  }

  checkall_senders(){
    this.senderCheckedValue = !this.senderCheckedValue
    $("#all_senders").find("td .round input[type=checkbox]").prop("checked", this.senderCheckedValue);
  }

  search_sender_invoice() {
    if (this.searchTarget) {
      search["search"] = this.searchTarget.value;
    }
    console.log("sender search", search["search"]);
    $.ajax({
      url: "/bookings/sender_search",
      type: "GET",
      dataType: "script",
      data: search,
    });
  }    

  search_invoice_bookings() {
    if(this.hasDaterangeTarget){
      var picker = $(this.daterangeTarget).data('daterangepicker')
      booking_search["end_date"] = formatDate(picker.endDate._d)
      booking_search["start_date"] = formatDate(picker.startDate._d)
    }

    if (this.driversTargets) {
      booking_search["pickup_status"] = [];
      this.driversTargets.map((t) => {
        if (t.checked) {
          booking_search["driver_ids"].push(t.value);
        }
      });
    }

    if (this.sendersTargets) {
      booking_search["pickup_status"] = [];
      this.sendersTargets.map((t) => {
        if (t.checked) {
          booking_search["sender_ids"].push(t.value);
        }
      });
    } 

    if (this.pickupstatusesTargets) {
      booking_search["pickup_status"] = [];
      this.pickupstatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["pickup_status"].push(t.value);
        }
      });
    }

    if (this.deliverystatusesTargets) {
      booking_search["delivery_status"] = [];
      this.deliverystatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["delivery_status"].push(t.value);
        }
      });
    }

    if (this.invoicestatusesTargets) {
      booking_search["invoice_status"] = [];
      this.invoicestatusesTargets.map((t) => {
        if (t.checked) {
          booking_search["invoice_status"].push(t.value);
        }
      });
    }    

    if (this.queryTarget) {
      booking_search["query"] = this.queryTarget.value;
    }

    return $.ajax({
      url: "/bookings/invoicing_overview",
      type: "GET",
      dataType: "script",
      data: booking_search,
    });
  }
}

var formatDate = function(sDate){
  return sDate.getFullYear() +
    "-" +
    (sDate.getMonth() + 1) +
    "-" +
    sDate.getDate();
}