import { Controller } from "stimulus";
const search = { search: "", statuses: [] };
const performance_search = {query: "", start_date: "",
  end_date: "",};
export default class extends Controller {
  static targets = [
    "search", 
    "status", 
    "editBtn", 
    "submitBtn", 
    "form", 
    "modalTitle",
    "searchperformance"
  ];

  search_sender(e) {
    if (this.statusTargets) {
      search["statuses"] = [];
      this.statusTargets.map(t => {
        if (t.checked) {
          //if (t.value == "1") {
            //search["statuses"].push(t.value, "5");
          //} else {
            search["statuses"].push(t.value);
          //}
        }
      });
    }
    if (this.searchTarget) {
      search["search"] = this.searchTarget.value;
    }
    $.ajax({
      url: "/admin/senders",
      type: "GET",
      dataType: "script",
      data: search
    });
  }

  search_sender_performance(e){
    updateDateRange($("#senderreportrange").val())
    if(this.searchperformanceTarget){
      performance_search["query"] = this.searchperformanceTarget.value;
    }
    $.ajax({
      url: "/admin/senders/senders_performance",
      type: "GET",
      dataType: "script",
      data: performance_search
    })
  }

  show_hide_update() {
    this.submitBtnTarget.classList.remove("d-none");
    this.submitBtnTarget.value = "Update";
    this.formTarget.classList.remove("form-inactive");
    this.editBtnTarget.classList.add("d-none");
    var id = this.modalTitleTarget.dataset.senderid
    this.modalTitleTarget.innerHTML = "Edit Sender ID" + " " + id
  }
}

var updateDateRange = function (value) {
  var parts = value.split("-");
  if (parts.length > 1) {
    var start_date = parts[0];
    var end_date = parts[1];
    performance_search["start_date"] = start_date;
    performance_search["end_date"] = end_date;
    console.log("filter daterange", start_date, end_date);
  }
};

$(function() {
  var date = $("#senderreportrange");
  date.on("change",function(e){
    updateDateRange(e.target.value);
    $.ajax({
      url: "/admin/senders/senders_performance",
      type: "GET",
      dataType: "script",
      data: performance_search
    })
  })
})

$(document).on('turbolinks:load',function(){
  $(function(){
    $("#pef-xl").on("click",function(){
      var query = performance_search["query"]
      var start_date = performance_search["start_date"]
      var end_date = performance_search["end_date"]
      $("#pef-xl").attr("href","/admin/senders/senders_performance.xlsx?query="+query+"&start_date="+start_date+"&end_date="+end_date+"");
    })
  })
  $(function(){
    //updateDateRange($("#senderreportrange").val())
    $("#sender_performance").val(performance_search["query"]);
    if(performance_search["start_date"]  && performance_search["end_date"]){
      $("#senderreportrange").val(performance_search["start_date"]+"-"+performance_search["end_date"])
    }
  })
})

$(document).on('turbolinks:load',function(){
  $(function(){
    $("#sender-search").val(search["search"]);
    $("#statuses li").each(function(){
      var status = $(this).find('input').val()
      if(search["statuses"].includes(status)){
        $(this).find('input').prop("checked",true)
      }
    })
  })
  $(function(){
    $("#sender-xl").on("click",function(){
      var search_query = search["search"]
      var statuses = search["statuses"]
      $("#sender-xl").attr("href","/admin/senders.xlsx?search="+search_query+"&statuses="+statuses+"");
    })
  })
})