import { Controller } from "stimulus";
const search = { query: "" };
export default class extends Controller {
	static targets = [ 
    "query",
    "form" ,
    "submitBtn",
    "editBtn",
    "cancelBtn",
    "deleteFavourite",
    "favouriteId",
    "locationLabel"
  ]

	search_favourite_locations(){
    if (this.queryTarget) {
      search["query"] = this.queryTarget.value;
    }
    $.ajax({
      url: "/favourite_locations",
      type: "GET",
      dataType: "script",
      data: search
    });
	}

  show_hide_update() {
    this.cancelBtnTarget.classList.remove("d-none");
    this.submitBtnTarget.classList.remove("d-none");
    this.formTarget.classList.remove("form-inactive");
    this.editBtnTarget.classList.add("d-none");
  }  

  pass_favourite_id() {
    var favourite_location_id = this.deleteFavouriteTarget.value
    console.log("SDFSADFASFDSA", favourite_location_id)
  }
}