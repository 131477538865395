export const defaultDriverStatus = [
  {
    status: "Offline",
    isChecked: false,
  },
  {
    status: "Busy",
    isChecked: true,
  },
  {
    status: "Free",
    isChecked: true,
  },
];
export const defaultModeOfDriver = [
  {
    mode: "bicycle",
    isChecked: true,
  },
  {
    mode: "car",
    isChecked: true,
  },
  {
    mode: "motorbike",
    isChecked: true,
  },
  {
    mode: "truck",
    isChecked: true,
  },
  {
    mode: "van",
    isChecked: true,
  },
  {
    mode: "walking",
    isChecked: true,
  },
];

// default filter for task
export const defaultTaskFilter = [
  { taskStatus: "unassigned", isChecked: true },
  { taskStatus: "assigning", isChecked: true },
  { taskStatus: "accepted", isChecked: true },
  { taskStatus: "started", isChecked: true },
  { taskStatus: "arrived", isChecked: true },
  { taskStatus: "successful", isChecked: false },
  { taskStatus: "failed", isChecked: true },
  { taskStatus: "cancelled", isChecked: true },
];

export function createGeoJsonObj({ id, status, task_id, geometry, mode }) {
  const modeOfTransportArray = [
    "car",
    "van",
    "bicycle",
    "walking",
    "truck",
    "motorbike",
  ];

  return {
    id,
    type: "Feature",
    properties: {
      id,
      status,
      task_id: task_id[0],
      bearing: 0,
      mode,
    },
    geometry,
  };
}

export function createTaskGeoJsonObj({ id, status, geometry, taskType }) {
  return {
    id,
    type: "Feature",
    properties: {
      id,
      status,
      taskType,
    },
    geometry: {
      type: "Point",
      coordinates: geometry,
    },
  };
}

export function CheckForTheDriver({ map, dataSource, id }) {
  const data = map.getSource(dataSource)._data;
  let driverIndex = data.features.findIndex((item) => item.id === id);
  return {
    data,
    driverIndex,
  };
}

// Function for rendering the custom icons on map
// export function loadImage(map) {
//   const transportersStatus = ["free", "busy", "offline"];
//   const modeOfTransporters = [
//     "car",
//     "van",
//     "bicycle",
//     "truck",
//     "motorbike",
//     "walking",
//   ];
//   const taskType = ['Pickup', 'Delivery'];
//   const taskStatus = ['accepted', 'arrived', 'assigning', 'cancelled', 'failed', 'started', 'successful', 'unassigned'];
//   // for driver and other mode of transport
//   for (let i = 0; i < modeOfTransporters.length; i++) {
//     for (let j = 0; j < transportersStatus.length; j++) {
//       map.loadImage(
//         require(`../../assets/images/${modeOfTransporters[i]}-${transportersStatus[j]}.png`),
//         function (error, image) {
//           if (error) throw error;
//           map.addImage(
//             `${modeOfTransporters[i]}-${transportersStatus[j]}`,
//             image
//           );
//         }
//       );
//     }
//   }

//   // for task pin
//   for (let i = 0; i < taskType.length; i++) {
//     for (let j = 0; j < taskStatus.length; j++) {
//       map.loadImage(require(`../../assets/images/${taskType[i]}-${taskStatus[j]}.png`), function (
//         error,
//         image
//       ) {
//         if (error) throw error;
//         map.addImage(`${taskType[i]}-${taskStatus[j]}`, image);
//       });

//     }
//   }
// }

export function onRenderDriverOnMap(map, transporterData) {
  if (map.getSource("mainSource")) {
    map.getSource("mainSource").setData(transporterData);
  } else {
    map.addSource("mainSource", {
      type: "geojson",
      data: transporterData,
    });

    map.addLayer({
      id: "busyDriver",
      type: "symbol",
      source: "mainSource",
      layout: {
        "icon-image": ["concat", ["get", "mode"], "-busy"],
        "icon-size": 1,
        "icon-rotate": ["get", "bearing"],
      },
      filter: [
        "all",
        ["has", "status"],
        ["has", "task_id"],
        ["==", ["get", "status"], "true"],
        ["!=", ["get", "task_id"], 0],
      ],
    });

    map.addLayer({
      id: "freeDriver",
      type: "symbol",
      source: "mainSource",
      layout: {
        "icon-image": ["concat", ["get", "mode"], "-free"],
        "icon-size": 1,
        // "icon-allow-overlap": true,
        "icon-rotate": ["get", "bearing"],
      },
      filter: [
        "all",
        ["has", "status"],
        ["has", "task_id"],
        ["==", ["get", "status"], "true"],
        ["==", ["get", "task_id"], 0],
      ],
    });
    map.addLayer({
      id: "offlineDriver",
      type: "symbol",
      source: "mainSource",
      layout: {
        "icon-image": ["concat", ["get", "mode"], "-offline"],
        "icon-size": 1,
        "icon-rotate": ["get", "bearing"],
      },
      filter: ["all", ["has", "status"], ["==", ["get", "status"], "false"]],
    });
  }
}

// function to render task
export function renderTasksOnMap(map, tasksData) {
  if (map.getSource("taskSource")) {
    console.log(map.getSource("taskSource"), "task source");
    map.getSource("taskSource").setData(tasksData);
  } else {
    map.addSource("taskSource", {
      type: "geojson",
      data: tasksData,
    });

    map.addLayer({
      id: "pickupTask",
      type: "symbol",
      source: "taskSource",
      layout: {
        "icon-image": ["concat", "Pickup-", ["get", "status"]],
        "icon-size": 1,
        // "icon-allow-overlap": true,
        // "icon-ignore-placement": true
        // 'icon-rotate': ['get', 'bearing']
      },
      filter: [
        "all",
        ["has", "status"],
        ["has", "id"],
        ["has", "taskType"],
        ["==", ["get", "taskType"], "pickup"],
      ],
    });
    map.addLayer({
      id: "deliveryTask",
      type: "symbol",
      source: "taskSource",
      layout: {
        "icon-image": ["concat", "Delivery-", ["get", "status"]],
        "icon-size": 1,
        // "icon-allow-overlap": true,
        // "icon-ignore-placement": true

        // 'icon-rotate': ['get', 'bearing']
      },
      filter: [
        "all",
        ["has", "status"],
        ["has", "id"],
        ["has", "taskType"],
        ["==", ["get", "taskType"], "delivery"],
      ],
    });
  }
}

export function renderTaskOnClear(map, taskData) {
  if (map.getSource("taskSource")) {
    map.getSource("taskSource").setData(taskData);
  }
}
