/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log("Hello World from Webpacker");
//= require_tree

require("@rails/ujs").start();
require("turbolinks").start();
import "javascripts/utils.js";
import "bootstrap";
import "popper.js";
import "intl-tel-input";
import "moment";
require("javascripts/jquery.validate.min.js");
require("controllers");
require("javascripts/google_autocomplete");
require("javascripts/transporter.js");
require("javascripts/handlers");
require("javascripts/singleTracking");
require("webpack-jquery-ui/datepicker");
require("expose-loader?Swal!javascripts/sweetalert2.min");
require("jquery-mask-plugin");

import Common from "javascripts/common";
import { initMaterialInput } from "javascripts/material-input";

//css
import "../stylesheets/application";

//images
import "javascripts/allImages";

window.App = {
  Common: Common,
  initMaterialInput: initMaterialInput,
};

$.jMaskGlobals.watchDataMask = true;

import "controllers";
