
$(function(){
	$("#area-demand-date").on('change',function(e){
		var selected_date = e.target.value.split("-");
		var start_date = selected_date[0];
    var end_date = selected_date[1];
    $("#area-demand-start-date").val(start_date);
    $("#area-demand-end-date").val(end_date);
	});

	$("#coverage_areas_csv").change(function(){
	  $(".custom-file-label").text(this.files[0].name);
	});
})
