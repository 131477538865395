const setActive = (el, active) => {
  const formField = el.closest('.material-input');
  if (active) {
    formField.classList.add("is-focused");
  } else {
    formField.classList.remove("is-focused");
    el.value === ""
      ? formField.classList.remove("is-filled")
      : formField.classList.add("is-filled");
  }
};
export const initMaterialInput = () => {
  [].forEach.call(document.querySelectorAll(".material-input .form-control"), el => {
    if (el.value !== ""){
      setActive(el, true)
    }     
    else if (el.autofocus) { 
      setActive(el, true)
    }
    el.onblur = () => {
      setActive(el, false);
    };
    el.onfocus = () => {
      setActive(el, true);
    };
  });
};
