import { Controller } from "stimulus";
import { validation_rules } from "javascripts/validations";
export default class extends Controller {
  static targets = ["form", "submitBtn", "editBtn"];
  validate(event) {
    var form_valid = false;
    var form = $(this.formTarget);
    if (form_valid) {
      form_valid = false; // reset flag
      return; // let the event bubble away
    }
    event.preventDefault();
    var rules =
      validation_rules[this.submitBtnTarget.getAttribute("data-target-form")];
    form.validate(rules);
    if (form.valid()) {
      form_valid = true;
      if (form.data("remote")) {
        Rails.fire(form[0], "submit");
      } else {
        form.submit();
      }
    } else {
      form_valid = false;
    }
  }
}
