import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["query"];

  search_cities() {
    var search = this.queryTarget.value;
    $.ajax({
      url: "/admin/cities",
      type: "GET",
      dataType: "script",
      data: { search: search }
    });
  }

  search_transporters() {
    var search = this.queryTarget.value;
    var id = this.queryTarget.dataset.city_id
    $.ajax({
      url: `/admin/cities/${id}/transporters`,
      type: "GET",
      dataType: "script",
      data: { search: search }
    }); 
  }
}
